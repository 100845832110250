import React from "react";
import Sidebar from "./components/sideBar/sidebar";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Loginpage from "./Pages/loginpage/loginpage";
import { ToastContainer } from "react-toastify";
// import Reset from "./Pages/resetPassword/reset";

const App = () => {
  var user = localStorage.getItem("USER");

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <BrowserRouter>{user ? <Sidebar/> : <Loginpage/>}</BrowserRouter>
      
    </>
  );
};

export default App;
