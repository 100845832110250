/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import WebIcon from "@mui/icons-material/Web";
import PersonIcon from "@mui/icons-material/Person";

import { Box } from "@mui/system";
import { TextField } from "@mui/material";
import "./editbutton.css";
import axios from "axios";
import Url from "../../URL/url";
import { toast } from "react-toastify";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Addusers({ responseData, userData }) {
  const [open, setOpen] = useState(false);
  const [getdatas, setgetdatas] = useState({});
  useEffect(() => {
    const obj = {
      username: userData?.username,
      email: userData?.email,
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      website: userData?.website,
    };

    setgetdatas(obj);
  }, [userData]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const getvalues = (e) => {
    setgetdatas({ ...getdatas, [e.target.name]: e.target.value });
  };
  // let getId=JSON.parse( localStorage.getItem('USER'))?.id
  const token = localStorage.getItem("TOKEN");
  const headers = { Authorization: `Bearer ${token}` };

  const handleClose = () => {
    setOpen(false);
    axios
      .patch(`${Url()}/api/v1/users/updateMe`, getdatas, { headers })
      .then((e) => {
        toast("Edit profile successfully");
        responseData(e);
      });
  };

  return (
    <div>
      <div>
        <Button
          variant="contained"
          style={{ padding: "5px 134px" }}
          color="primary"
          onClick={handleClickOpen}
        >
          Edit
        </Button>
      </div>

      <BootstrapDialog onClose={handleClose} open={open}>
        <BootstrapDialogTitle
          style={{ width: "600px", textAlign: "center" }}
          onClose={handleClose}
        >
          Edit User
        </BootstrapDialogTitle>

        <div style={{ padding: "0px 20px" }}>
          <div className="edituserbox">
            <form className="formfield">
              <Box
                className="textfield"
                sx={{ display: "flex", alignItems: "flex-end" }}
              >
                <AccountCircle
                  sx={{ color: "action.active", mr: 1, my: 0.5 }}
                />
                <TextField
                  aria-readonly
                  id="input-with-sx"
                  style={{ width: "220px" }}
                  label="User Name"
                  variant="standard"
                  name="username"
                  onChange={getvalues}
                  value={getdatas?.username}
                />
              </Box>

              <Box
                className="textfield"
                sx={{ display: "flex", alignItems: "flex-end" }}
              >
                <MarkunreadIcon
                  sx={{ color: "action.active", mr: 1, my: 0.5 }}
                />
                <TextField
                  id="input-with-sx"
                  style={{ width: "220px" }}
                  label="Email"
                  variant="standard"
                  name="email"
                  onChange={getvalues}
                  value={getdatas?.email}
                />
              </Box>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <PersonIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                  <TextField
                    id="input-with-sx"
                    style={{ width: "220px" }}
                    label="First Name"
                    variant="standard"
                    name="firstName"
                    onChange={getvalues}
                    value={getdatas?.firstName}
                  />
                </Box>

                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <PersonIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                  <TextField
                    id="input-with-sx"
                    style={{ width: "220px" }}
                    label="Last Name"
                    variant="standard"
                    name="lastName"
                    onChange={getvalues}
                    value={getdatas?.lastName}
                  />
                </Box>
              </div>
              {/* <Box
                className="textfield"
                sx={{ display: "flex", alignItems: "flex-end" }}
              >
                <WebIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                <TextField
                  id="input-with-sx"
                  style={{ width: "220px" }}
                  label="Website"
                  variant="standard"
                  name="website"
                  onChange={getvalues}
                  value={getdatas?.website}
                />
              </Box> */}
            </form>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <DialogActions>
            <Button
              style={{
                margin: "10px 5px",
                backgroundColor: "rgb(240, 240, 240)",
                color: "black",
              }}
              autoFocus
              onClick={handleClose}
            >
              Cancel
            </Button>
          </DialogActions>
          <DialogActions>
            <Button
              style={{ margin: "10px 5px", padding: "5px 20px" }}
              variant="contained"
              autoFocus
              onClick={handleClose}
            >
              Save
            </Button>
          </DialogActions>
        </div>
      </BootstrapDialog>
    </div>
  );
}
