/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Adduser from "../AddUsers/adduser";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Dialog from "@mui/material/Dialog";
import "./allusers.css";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import {
  FormControl,
  InputLabel,
  LinearProgress,
  Select,
  TablePagination,
} from "@mui/material";
import { toast } from "react-toastify";
import generateExcel from "zipcelx";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import HttpApis from "../../../URL/ServicePms";
// import { DownloadTableExcel } from "react-export-table-to-excel";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Allusers(props) {
  let [editUser, setEditUser] = useState([]);
  let [search, setsearch] = useState();
  const [referse, setreferse] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [editId, setEditId] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [filData, setFilData] = React.useState({});
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const Dropdown = [
    { id: 0, roles: "Admin" },
    { id: 1, roles: "Developer" },
    { id: 2, roles: "Tester" },
    { id: 3, roles: "PM" },
  ];
  const [roles, setRoles] = useState();

  const open = Boolean(anchorEl);

  useEffect(() => {
    renderFirst();
  }, [editUser, props.resultFunction, referse]);

  const renderFirst = async () => {
    const response = await HttpApis.getUsers();
    setData(response.data.data);
    setFilteredData(response.data.data);
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getValue = (e) => {
    setreferse();
  };

  const handleClick = (event, idd) => {
    setEditId(idd);
    setAnchorEl(event.currentTarget);
  };
  const handleFilter = () => {
    let fildata = data.filter((val) => val._id === editId);
    setFilData(...fildata);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteUser = async (rowid) => {
    if (confirm("Delete this User !")) {
      await HttpApis.deleteUserProject(rowid).then(() => {
        toast("deleted sucessfully");
        renderFirst();
      });
    }
  };

  function getExcel() {
    const config = {
      sheet: {
        data: [],
      },
    };

    const dataSet = config.sheet.data;
    data.forEach((headerGroup) => {
      const headerRow = [];
      if (headerGroup.headers) {
        headerGroup.headers.forEach((column) => {
          headerRow.push(...getHeader(column));
        });
      }

      dataSet.push(headerRow);
    });

    return generateExcel(config);
  }
  const handleSubmit= async(e)=>{
    e.preventDefault()
    const response = await HttpApis.editUser(filData);
    setEditUser(response);
    toast("User Edited Sucessfully");
    setAnchorEl(null);

    setOpenModal(false)


  }
  function getHeader(column) {
    if (column.totalHeaderCount === 1) {
      return [
        {
          value: column.Header,
          type: "string",
        },
      ];
    } else {
      const span = [...Array(column.totalHeaderCount - 1)].map((x) => ({
        value: "",
        type: "string",
      }));
      return [
        {
          value: column.Header,
          type: "string",
        },
        ...span,
      ];
    }
  }
  
  async function handleChange(event) {
    setRoles(event.target.value);
    setsearch(event.target.value)
    const filteredData = data.filter((item) => {
      if (item?.role === event.target.value) {
        return item;
      }
      if (!search) {
        return item;
      } else if (
        item.username
          .toLowerCase()
          .includes(search.toLowerCase())
      ) {
        return item;
      }
    });
    setFilteredData(filteredData);
  }

  return (
    <>
      <div className="dashboard_table">
        <div className="table_heading" style={{ color: "black" }}>
          Users List
          <span>
            <Adduser getProps={getValue} />
          </span>
        </div>
        <div className="table_box">
          <div style={{ display: "flex", padding: "10px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "baseline",
                gap: "0.5rem",
              }}
            >
              <FormControl sx={{ m: 1, minWidth: "18rem" }} size="small">
                <InputLabel id="demo-select-small">Role</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  label="Filter"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                >
                  {Dropdown.map((item, index) => {
                    return <MenuItem value={item.roles}>{item.roles}</MenuItem>;
                  })}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                size="small"
                style={{ width: "377px", marginTop: "10px" }}
                label="Search"
                id="fullWidth"
                onChange={(e) => handleChange(e)}
              />
            </Box>
          </div>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer
              options={{
                exportButton: true,
                exportAllData: true,
              }}
              style={{ marginTop: "20px", maxHeight: "400px" }}
              component={Paper}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      sx={{
                        background: "white !important",
                        color: "black !important",
                      }}
                    >
                      User Name
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{
                        background: "white !important",
                        color: "black !important",
                      }}
                    >
                      Email
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{
                        background: "white !important",
                        color: "black !important",
                      }}
                    >
                      First Name
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{
                        background: "white !important",
                        color: "black !important",
                      }}
                    >
                      Last Name
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{
                        background: "white !important",
                        color: "black !important",
                      }}
                    >
                      Role
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{
                        background: "white !important",
                        color: "black !important",
                      }}
                    >
                      Action
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                {filteredData ? (
                  <TableBody>
                    {filteredData.length !== 0 ? (
                      filteredData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <StyledTableRow key={row.name}>
                              <StyledTableCell component="th" scope="row">
                                {row.username}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.email}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.firstName}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.lastName}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.role}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <MoreVertIcon
                                  id="demo-positioned-button"
                                  aria-controls={
                                    open ? "demo-positioned-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                  onClick={(e) => handleClick(e, row._id)}
                                  cursor="pointer"
                                />
                                <div>
                                  <Menu
                                    id="demo-positioned-menu"
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                  >
                                    <MenuItem
                                      onClick={(e) => {
                                        handleFilter();
                                        return setOpenModal(true);
                                      }}
                                    >
                                      Edit
                                    </MenuItem>
                                    <BootstrapDialog
                                      onClose={() => setOpenModal(false)}
                                      open={openModal}
                                    >
                                      <h1
                                        style={{
                                          display: "flex",
                                          alignItem: "center",
                                          marginLeft: "24rem",
                                        }}
                                      >
                                        Edit User
                                      </h1>
                                      <button
                                        onClick={() => setOpenModal(false)}
                                      ></button>
                                      <form
                                        style={{
                                          padding: " 0rem 3.5rem ",
                                          minWidth: "60rem !important",
                                        }}
                                        required
                                      >
                                        <fieldset>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div className="User_Name">
                                              <label
                                                className="txtlabel"
                                                for="name"
                                              >
                                                User Name
                                              </label>
                                              <input
                                                sx={{ display: "flex" }}
                                                onChange={(e)=>setFilData((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))}
                                                className="txtfield"
                                                type="text"
                                                id="name"
                                                name="username"
                                                value={filData?.username}
                                                required
                                              />
                                            </div>
                                          </div>
                                          <div>
                                            <label
                                              className="txtlabel"
                                              for="name"
                                            >
                                              Email
                                            </label>
                                            <input
                                              onChange={(e)=>setFilData((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))}
                                              className="txtfield"
                                              value={filData?.email}
                                              type="text"
                                              id="name"
                                              name="email"
                                              required
                                            />
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div>
                                              <label
                                                className="txtlabel"
                                                for="name"
                                              >
                                                First Name
                                              </label>
                                              <input
                                                onChange={(e)=>setFilData((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))}
                                                
                                                className="txtfield"
                                                type="text"
                                                id="name"
                                                name="firstName"
                                                value={filData?.firstName}
                                                required
                                              />
                                            </div>

                                            <div>
                                              <label
                                                className="txtlabel"
                                                for="name"
                                              >
                                                Last Name
                                              </label>
                                              <input
                                                onChange={(e)=>setFilData((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))}
                                              
                                                className="txtfield"
                                                type="text"
                                                id="name"
                                                name="lastName"
                                                value={filData?.lastName}
                                                required
                                              />
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div
                                              sx={{
                                                paddingTop: "2rem !important",
                                              }}
                                            >
                                              <label
                                                className="txtlabel"
                                                for="name"
                                              >
                                                Role
                                              </label>
                                              <select
                                                onChange={(e)=>setFilData((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))}
                          
                                                name="role"
                                                id="cars"
                                                className="selectfield"
                                                required
                                                value={filData?.role}
                                              >
                                                <option hidden>Select</option>
                                                <option>Admin</option>
                                                <option>PM</option>
                                                <option>Developer</option>
                                                <option>Tester</option>
                                              </select>
                                            </div>
                                          </div>
                                          <div className="button_s">
                                            <input
                                              type="button"
                                              value="Cancel"
                                              className="cancel_button"
                                              cursor="pointer"
                                              onClick={() =>
                                                setOpenModal(false)
                                              }
                                            />
                                            <input
                                            onClick={handleSubmit}
                                              type="submit"
                                              value="Submit"
                                            />
                                          </div>
                                        </fieldset>
                                      </form>
                                    </BootstrapDialog>
                                    <MenuItem
                                      onClick={() => {
                                        deleteUser(row._id);
                                        setAnchorEl(null);
                                      }}
                                    >
                                      Delete
                                    </MenuItem>
                                  </Menu>
                                </div>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div class="empty-state__message">
                            No records has been added yet.
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                ) : (
                  <StyledTableCell colSpan={8}>
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress />
                    </Box>{" "}
                  </StyledTableCell>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={data?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </>
  );
}
