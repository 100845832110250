import { AppBar } from "@mui/material";
import "./topBar.css";
import { useNavigate } from "react-router";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useEffect, useState } from "react";

const TopBar = ({ nameTop }) => {
  const navigate = useNavigate()
  const handleSubmit = () => {
  localStorage.clear();
  window.location.reload();
  navigate("/");
  
  };
  let [user, setUser] = useState("");
  
  useEffect(()=>{
    const user = localStorage.getItem("USER")
    setUser(JSON.parse(user).username)
  },[])
  
  return (
    <>
      <div className="MainContainer">
        <AppBar style={{ background: "#FFFFFF", height: "70px" }}>
          <div
            className="wrapperIcons"
            style={{
              display: "flex",
              paddingTop: "0rem",
              paddingBottom: "2rem",
              alignItems: "center",
            }}
          >
            <span className="Imageicon">
              <img src="Group.svg" alt="abc" />
              <h4
                style={{
                  color: "#3C4C6B",
                  paddingLeft: "6px",
                  paddingTop: "5px",
                }}
              >
                {nameTop}
              </h4>
            </span>


<span>
  <p className="wlcCard_title">WELCOME {user.toUpperCase()}</p>

</span>
            <span className="Icon">
              <LogoutOutlinedIcon
                onClick={handleSubmit}
                style={{ background: "#000000", marginRight: "2rem" }}
              />
            </span>
          </div>
        </AppBar>
      </div>
    </>
  );
};

export default TopBar;
