/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
import * as React from "react";
import "./AddProjectModal.css";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import HttpApis from "../../../URL/ServicePms"
import { makeStyles } from "@material-ui/core";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    padding: theme.spacing(1),
  },
}));

const useStyle = makeStyles({
  component: {
    backgroundColor: "#00B1E6 !important",
    color: "white !important",
    padding: "0.5rem 2.3rem !important",
    borderRadius: "5px",
  },
  sidebar: {
    color: "white",
    margin: "0px",
  },
});

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Inputfield({ postData, sidebar }) {
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate("/Projects");
  };
  let [getcategory, setcategory] = useState([]);
  const [project, setProject] = useState({
    name: "",
    projectManager: "",
    category: "",
    startDate: "",
    dueDate: "",
    status: "",
    projectDetails: ""
  });
  const [open, setOpen] = React.useState(false);
  const classes = useStyle();
  
  useEffect(() => {
    const allCategories = async () => {
      const response =  await HttpApis.getAllCategory()
       setcategory(response.data.data)
     };
     allCategories();
  }, []);

  const handleChange = (e) => {
    const alertTxt = "please enter valid details";
    const regex = /[0-9]/;

    if (
      regex.test(e.target.value) &&
      (e.target.name == "name" || e.target.name == "content")
    ) {
      toast(alertTxt);
      e.target.value = "";
    } else if (isNaN(e.target.value)) {
      const { name, value } = e.target;
      setProject({ ...project, [name]: value });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = async (e) => {
    e.preventDefault();
    project["user"] = JSON.parse(localStorage.getItem("USER")).id;
    await  HttpApis.postProjectApi(project).then((e) => {
      toast("Add project successfull");
      postData(e);
    });
    setOpen(false);
  };
  const handleClosed = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <Button
          className={sidebar ? classes.sidebar : classes.component}
          style={{
            padding: "0px",
            backgroundColor: "transparent",
            color: "white",
            display: "contents"
          }}
          onClick={handleClickOpen}
        >
          Add Project
        </Button>
        <BootstrapDialog
          onClose={handleClosed}
          open={open}
          fullWidth= {true}
          maxWidth={"md"}
        >
          <BootstrapDialogTitle
            // style={{ background: "red" }}
            style={{ textAlign: "center" }}
            onClose={handleClosed}
          >
            <h4>New Project</h4>
          </BootstrapDialogTitle>
          <div className="formbox">
            <form required onSubmit={handleClose}>
              <fieldset className="field">
                <div>
                  <label className="txtlabel" for="name">
                    Project Name
                  </label>
                  <input
                    onChange={handleChange}
                    className="txtfield"
                    type="text"
                    id="name"
                    name="name"
                    required
                  />
                </div>

                <div>
                  <label className="txtlabel" for="name">
                    Project Manager
                  </label>
                  <input
                    onChange={handleChange}
                    className="txtfield"
                    type="text"
                    id="pm"
                    name="projectManager"
                    required
                  />
                </div>

                <label className="txtlabel" for="email">
                  Category
                </label>
                <select
                  type="text"
                  onChange={handleChange}
                  className="categoryField"
                  name="category"
                  id="cars"
                  required
                >
                  {getcategory.map((a) => (
                    <>
                      <option value="select" hidden>
                        select
                      </option>
                      <option value={a._id}>{a.name}</option>
                    </>
                  ))}
                </select>
                <label className="txtlabel" for="email">
                  Status
                </label>
                <select
                  type="text"
                  onChange={handleChange}
                  className="categoryField"
                  name="status"
                  id="status"
                  required
                >
                    <>
                      <option value="select" hidden>
                        select
                      </option>
                      <option value="Completed">Completed</option>
                      <option value="Pending">Pending</option>
                    </>
                </select>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <label for="startDate">Start Date</label>
                    <input
                      onChange={handleChange}
                      className="datefield"
                      type="date"
                      id="date"
                      name="startDate"
                      required
                    />
                  </div>
                  <div>
                    <label for="Enddate">End Date</label>
                    <input
                      onChange={handleChange}
                      className="datefield"
                      type="date"
                      id="date"
                      name="dueDate"
                      required
                    />
                  </div>
                </div>
                <div className="textareafield">
                  <label className="txtlabel" for="bio">
                    Project Details
                  </label>
                  <textarea
                    style={{ margin: "0px" }}
                    onChange={handleChange}
                    id="bio"
                    name="content"
                    required
                  ></textarea>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "1rem",
                    }}
                  >
                    <input
                      type="button"
                      className="cancelButton"
                      value="Cancel"
                      cursor="pointer"
                      onClick={handleSubmit}
                    />
                    <input
                      type="submit"
                      className="addButton"
                      value="Add Project"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </BootstrapDialog>
      </div>
    </>
  );
}
