import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import "./addTaskStyle.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import HttpApis from "../../../URL/ServicePms"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
 
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Addfield(props) {
  const [field, setField] = useState({
    project: "",
    name: "",
    currentStatus: "",
    startDate: "",
    dueDate: "",
    estimatedHours: "",
    consumedHours: "",
    taskDetails: "",
  });

  let inputValue = (e) => {
    const num = /[0-9]/;
    if (
      num.test(e.target.value) &&
      e.target.name !== "project" &&
      e.target.name !== "currentStatus" &&
      e.target.name !== "startDate" &&
      e.target.name !== "dueDate" &&
      e.target.name == "estimatedHours" &&
      e.target.name == "consumedHours"
    ) {
      e.target.value = "";
    } else {
      setField({ ...field, [e.target.name]: e.target.value });
    }
  };

  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const handleCancel = () => {
    setOpen(false);
    navigate("/My-Task");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const [project, setproject] = useState([]);
 
  useEffect(() => {
    const projectData = async () => {
      const response = await HttpApis.getProjects();
      setproject(response.data.data.projects);
    };
    projectData();
  }, []);
    
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    field["user"] = JSON.parse(localStorage.getItem("USER")).id;
      HttpApis.addTasksProjectAPi(field).then((e) => {
          toast("Add task successfully");
          props.getstatus(e);
    });
  };

  return (
    <>
      <div sx={{ maxWidth: "60rem !important" }}>
          <Button
            style={{
              padding: "0px",
              color: "white",
              backgroundColor: "transparent",
            }}
            onClick={handleClickOpen}
            // color="primary"
          >
            Add Task
          </Button>
        <BootstrapDialog
          onClose={handleClose}
          open={open}
          className="custom_model"
        >
          <BootstrapDialogTitle
            style={{ minWidth: "600px", textAlign: "center" }}
            onClose={handleSubmit}
          >
            <h4>New Task</h4>
          </BootstrapDialogTitle>

          <div className="formbox" style={{ minWidth: "20rem" }}>
            <form onSubmit={handleSubmit}>
              <fieldset className="field">
                <label className="txtlabel" for="name">
                  Project Name
                </label>
                <select
                  onChange={inputValue}
                  className="datefield"
                  name="project"
                  id="cars"
                  required
                  style={{
                    margin: "0px",
                    width: "25rem",
                    padding: "0.4rem 0rem",
                  }}
                >
                  <option value="select" hidden>
                    select
                  </option>

                  {project.map((a) => (
                    <>
                      <option value={a._id}>{a.name}</option>
                    </>
                  ))}
                </select>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <label className="txtlabel" for="name">
                      Task Name
                    </label>
                    <input
                      className="txtfield"
                      type="text"
                      id="name"
                      name="name"
                      onChange={inputValue}
                      required
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div style={{ minWidth: "14rem" }}>
                    <label className="txtlabel" for="startDate">
                      Start Date
                    </label>
                    <input
                      className="dateBox"
                      type="date"
                      id="date"
                      name="startDate"
                      onChange={inputValue}
                      required
                      // value={field.startDate}
                    />
                  </div>
                  <div style={{ minWidth: "10rem" }}>
                    <label className="txtlabel" for="dueDate">
                      End Date
                    </label>
                    <input
                      className="dateBox"
                      type="date"
                      id="date"
                      name="dueDate"
                      onChange={inputValue}
                      required
                      // value={field.dueDate}
                    />
                  </div>
                  <div style={{ minWidth: "10rem" }}>
                    <label className="txtlabel" for="startDate">
                      Estimated Hours
                    </label>
                    <input
                      className="dateBox"
                      type="number"
                      id="date"
                      name="estimatedHours"
                      onChange={inputValue}
                      required
                      // value={field.estimatedHours}
                    />
                  </div>
                  <div style={{ minWidth: "5rem" }}>
                    <label className="txtlabel" for="Enddate">
                      Consumed Hours
                    </label>
                    <input
                      className="dateBox"
                      type="number"
                      id="date"
                      name="consumedHours"
                      onChange={inputValue}
                      required
                      // value={field.consumedHours}
                    />
                  </div>
                </div>
                <div>
                  <label className="txtlabel" for="name">
                    Status
                  </label>
                  <select
                    onChange={inputValue}
                    className="datefield"
                    name="currentStatus"
                    id="cars"
                    required
                  >
                    <option value="select" hidden>
                      select
                    </option>
                    <option value="Pending">Pending</option>
                    <option value="Completed">Completed</option>
                  </select>
                </div>
                <label className="txtlabel" for="Enddate">
                  Task Details
                </label>
                <textarea
                  style={{ width: "90%", margin: "0px" }}
                  id="date"
                  name="taskDetails"
                  onChange={inputValue}
                  rows="1"
                  cols="60"
                  required
                />
              </fieldset>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "1rem",
                  padding: "0.5rem 5rem 0.5rem 1rem",
                }}
              >
                <input
                  type="button"
                  className="cancelButton"
                  value="Cancel"
                  cursor="pointer"
                  onClick={handleCancel}
                />
                <input type="submit" value="Add Task" />
              </div>
            </form>
          </div>
        </BootstrapDialog>
      </div>
    </>
  );
}
