/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// import './sidebarTable.css'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Addcategories from "../addcategories/addcategories";
import "./categories.css";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { LinearProgress, Menu, MenuItem, TablePagination } from "@mui/material";
import CategoriesEditBtn from "./categoriesEditBtn";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { toast } from "react-toastify";
import HttpApis from "../../../URL/ServicePms";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Categories(props) {
  let [callbackfun, setcallbackfun] = useState(null);
  const [editTask, SetUpdateTask] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  let [search, setSearch] = useState();
  let [getdata, setgetdata] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ids, setIds] = React.useState("");

  useEffect(() => {
    getAllCategory();
  }, [props.resultFunction, callbackfun, editTask]);

  const getAllCategory = async () => {
    const response = await HttpApis.getAllCategory();
    setgetdata(response.data.data);
  };

  const editCategory = () => {
    SetUpdateTask(!editTask);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deleteCategory = async (e, ids) => {
    if (confirm("Delete this Category !")) {
      await HttpApis.deleteCategoryByRowId(ids?.id).then(() => {
        toast("deleted sucessfully");
        getAllCategory();
      });
    } else {
      alert("Error");
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event, id, name, description) => {
    setAnchorEl(event.currentTarget);
    const option = { name: name, description: description, id: id };
    setIds(option);
  };

  const handleCloseMenuPopup = () => {
    setAnchorEl(null);
  };

  const handlePopClose = (data) => {
    setOpenModal(data);
  };

  return (
    <>
      <div className="dashboard_table">
        <div className="table_heading" style={{ color: "black" }}>
          Category
          <span className="categoryButton">
            <Addcategories callbackfun={setcallbackfun} />
          </span>
        </div>
        <div className="table_box">
          <div style={{ display: "flex", padding: "17px 10px 2px 10px" }}>
            <div>
              <Box
                sx={{
                  width: 500,
                  maxWidth: "100%",
                }}
              >
                <TextField
                  fullWidth
                  size="small"
                  style={{ width: "377px" }}
                  label="Search"
                  id="fullWidth"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Box>
            </div>
          </div>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer
              style={{ marginTop: "20px", maxHeight: "400px" }}
              component={Paper}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      sx={{
                        background: "white !important",
                        color: "black !important",
                        fontSize: "1rem",
                      }}
                    >
                      S.No
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{
                        background: "white !important",
                        color: "black !important",
                        fontSize: "1rem",
                      }}
                    >
                      Name
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{
                        background: "white !important",
                        color: "black !important",
                        fontSize: "1rem",
                      }}
                    >
                      Description
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{
                        background: "white !important",
                        color: "black !important",
                        fontSize: "1rem",
                      }}
                    >
                      Action
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                {getdata ? (
                  <TableBody>
                    {getdata.length !== 0 ? (
                      getdata

                        .filter((row) => {
                          if (!search) {
                            return row;
                          } else if (
                            row.name
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          ) {
                            return row;
                          }
                        })
                        .map((rows, index) => (
                          <StyledTableRow key={rows._id}>
                            <StyledTableCell component="th" scope="row">
                              {index + 1}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {rows.name}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {rows.description}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <div>
                                <MoreVertIcon
                                  id="demo-positioned-button"
                                  aria-controls={
                                    open ? "demo-positioned-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                  onClick={(e) => {
                                    handleClick(
                                      e,
                                      rows._id,
                                      rows?.name,
                                      rows?.description
                                    );
                                  }}
                                  cursor="pointer"
                                />

                                <Menu
                                  id="demo-positioned-menu"
                                  aria-labelledby="demo-positioned-button"
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleCloseMenuPopup}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      setOpenModal(true);
                                      setAnchorEl(null);
                                    }}
                                  >
                                    Edit
                                  </MenuItem>
                                  <MenuItem
                                    onClick={(e) => {
                                      deleteCategory(e, ids);
                                    }}
                                  >
                                    Delete
                                  </MenuItem>
                                </Menu>
                              </div>
                              {openModal && (
                                <CategoriesEditBtn
                                  opens={openModal}
                                  setOpens={handlePopClose}
                                  row={ids}
                                  updateEditCategory={editCategory}
                                />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div class="empty-state__message">
                            No records has been added yet.
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                ) : (
                  <StyledTableCell colSpan={8}>
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress />
                    </Box>{" "}
                  </StyledTableCell>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={getdata?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </>
  );
}
