/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import Url from "../../../URL/url";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
// import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function MyTaskEditBtn(props) {
  const {
    opens,
    row: {
      id,
      project,
      name,
      taskDetails,
      startDate,
      dueDate,
      estimatedHours,
      consumedHours,
      status,
    },
    setOpens,
    updateEditTask,
  } = props;

  const start = moment(startDate.split("T")[0]).format("YYYY-MM-DD");
  const due = moment(dueDate.split("T")[0]).format("YYYY-MM-DD");
  const [editProjectForm, setEditProjectForm] = useState({});

  useEffect(() => {
    const Obj = {
      name: name,
      project: project,
      taskDetails: taskDetails,
      startDate: start,
      dueDate: due,
      estimatedHours: estimatedHours,
      consumedHours: consumedHours,
      status: status,
    };
    setEditProjectForm(Obj);
  }, []);

  const handleClose = () => {
    setOpens(false);
  };

  const setUserData = (e) => {
    let name = e.target?.name;
    let value = e.target?.value;
    setEditProjectForm({ ...editProjectForm, [name]: value });
  };

  const headers = { Authorization: `Bearer ${localStorage.getItem("TOKEN")}` };

  const editProject = async (e) => {
    e.preventDefault();
    await axios
      .patch(`${Url()}/api/v1/tasks/${id}`, editProjectForm, { headers })
      .then((e) => {
        toast("task edited sucessfully");
        props.updateEditTask(e.data.data);
      });
    handleClose();
  };
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={opens}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{ textAlign: "center" }}
        >
          <h4> Edit </h4>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography style={{ width: "520px" }} gutterBottom>
            <form onSubmit={editProject}>
              <div
                className="editField"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "0.5rem",
                }}
              >
                <div>
                  <TextField
                    style={{ width: "250px", height: "40px" }}
                    size="small"
                    id="outlined-helperText"
                    label="Project Name"
                    defaultValue={project?.name}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>

                <div>
                  <TextField
                    style={{ width: "250px", height: "40px" }}
                    size="small"
                    id="outlined-helperText"
                    label="Task Name"
                    name="name"
                    onChange={setUserData}
                    defaultValue={editProjectForm?.name}
                  />
                </div>
              </div>

              <div
                className="editField"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "0.5rem",
                }}
              >
                <div>
                  <TextField
                    style={{ width: "250px", height: "40px" }}
                    size="small"
                    id="outlined-helperText"
                    label="Task Details"
                    name="taskDetails"
                    onChange={setUserData}
                    defaultValue={editProjectForm?.taskDetails}
                  />
                </div>
              </div>

              <div
                className="editField"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "0.5rem",
                }}
              >
                <div>
                  <TextField
                    type="date"
                    size="small"
                    label="Start Date"
                    name="startDate"
                    defaultValue={editProjectForm?.startDate}
                    onChange={setUserData}
                    style={{ width: "250px", height: "40px" }}
                  />
                </div>
                <div>
                  <div>
                    <TextField
                      type="date"
                      size="small"
                      label="Due Date"
                      name="dueDate"
                      defaultValue={editProjectForm?.dueDate}
                      onChange={setUserData}
                      style={{ width: "250px", height: "40px" }}
                    />
                  </div>
                </div>
              </div>

              <div
                className="editField"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "0.5rem",
                }}
              >
                <div>
                  <TextField
                    type="number"
                    style={{ width: "250px", height: "40px" }}
                    size="small"
                    id="outlined-helperText"
                    label="Estimated Time"
                    name="estimatedHours"
                    onChange={setUserData}
                    defaultValue={editProjectForm?.estimatedHours}
                  />
                </div>
                <div>
                  <div>
                    <TextField
                      type="number"
                      style={{ width: "250px", height: "40px" }}
                      size="small"
                      id="outlined-helperText"
                      label="Consumed Time"
                      name="consumedHours"
                      onChange={setUserData}
                      defaultValue={editProjectForm?.consumedHours}
                    />
                  </div>
                </div>
              </div>

              <div
                className="editField"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>
                  <div>
                    {/* <TextField
                      id="outlined-select-currency"
                      select
                      style={{ width: "250px", height: "40px" }}
                      size="small"
                      label="Status"
                      name="currentStatus"
                      onChange={setUserData}
                      defaultValue={editProjectForm?.currentStatus}
                    >
                      <MenuItem value="Pending"> Pending </MenuItem>
                      <MenuItem value="Completed">
                      
                        Completed
                      </MenuItem>
                    </TextField> */}
                    <FormControl
                      name="currentStatus"
                      onChange={setUserData}
                      defaultValue={editProjectForm?.status}
                    >
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Status
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="pending"
                          control={<Radio />}
                          label="Pending"
                        />
                        <FormControlLabel
                          value="completed"
                          control={<Radio />}
                          label="Completed"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </div>

              <input type="submit" value="Submit" />
            </form>
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}
