import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import Url from "../../URL/url";
import "./changepassword.css";
import { useNavigate } from "react-router";

export default function Changepassword() {
  const [open, setOpen] = useState(true);
  const [values, setValues] = useState({
    passwordCurrent: "",
    password: "",
    passwordConfirm: "",
  });

  const handleChange = (prop) => {
    setValues({ ...values, [prop.target.name]: prop.target.value });
  };

  let token = localStorage.getItem("TOKEN");
  let headers = { Authorization: `Bearer ${token}` };
  let saveBtn = (e) => {
    e.preventDefault();
    setOpen(true);
    axios
      .patch(`${Url()}/api/v1/users/updateMyPassword`, values, { headers })
      .then((e) => {
        alert("password changed successfully");
        localStorage.removeItem("TOKEN");
        localStorage.removeItem("USER");
        window.location.reload();
      })
      .catch((e) => {
        alert("Your old password is wrong");
        window.location.reload();
      });
  };

  const handleClickShowPassword1 = () => {
    setValues({
      ...values,
      showPassword1: !values.showPassword1,
    });
  };
  const handleClickShowPassword2 = () => {
    setValues({
      ...values,
      showPassword2: !values.showPassword2,
    });
  };
  const handleClickShowPassword3 = () => {
    setValues({
      ...values,
      showPassword3: !values.showPassword3,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();
  const handleCancel = () => {
    setOpen(false);
    navigate("/changepassword");
  };

  return (
    <>
      <div className="passwordbox">
        <div className="boxtitle">
          <h3>Change Password</h3>
        </div>
        <div className="inputsfields">
          <form onSubmit={saveBtn}>
            <div className="singleinput">
              <FormControl sx={{ m: 1, width: "34ch" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Old Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={values.showPassword1 ? "text" : "password"}
                  required
                  onChange={handleChange}
                  name="passwordCurrent"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword1}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        aria-required
                      >
                        {values.showPassword1 ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </div>

            <div className="singleinput">
              <FormControl sx={{ m: 1, width: "34ch" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  New Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={values.showPassword2 ? "text" : "password"}
                  required
                  onChange={handleChange}
                  name="password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-required
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword2}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword2 ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </div>

            <div className="singleinput">
              <FormControl sx={{ m: 1, width: "34ch" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Confirm New Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={values.showPassword3 ? "text" : "password"}
                  required
                  onChange={handleChange}
                  name="passwordConfirm"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-required
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword3}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword3 ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                <div className="futerbutton">
                  <input
                    type="button"
                    className="cancelButton"
                    value="Cancel"
                    cursor="pointer"
                    onClick={handleCancel}
                  />
                  <input
                    type="submit"
                    value="  Save  "
                    style={{ padding: "0rem 2rem !important" }}
                  />
                </div>
              </FormControl>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
// import React from "react";
// const Changepassword=()=>{
//   return (
//     <p>hgjhgjhgjgjhgjh</p>
//   )
// }
// export default Changepassword
