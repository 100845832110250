/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */

import React, { useEffect, useState } from "react";
import "./profile.css";
import { CameraAlt } from "@mui/icons-material";
import Addusers from "../../components/editbutton/editbutton";
import { TextField } from "@mui/material";
import axios from "axios";
import Url from "../../URL/url";
import Avatar from "@mui/material/Avatar";
import CircularProgress from "@mui/material/CircularProgress";
export default function Profile({ changeIMage }) {
  // const user = JSON.parse(localStorage.getItem("USER"));
  const token = localStorage.getItem("TOKEN");
  const headers = { Authorization: `Bearer ${token}` };
  const [imageUrl, setImageUrl] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  // const [newData, setNewdata] = useState(null);
  const [refresh, getrefresh] = useState(false);

  const getimage = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(event.target.files[0]);
    }
  };

  useEffect(() => {
    axios
      .get(`${Url()}/api/v1/users/me`, {
        headers,
      })
      .then((res) => {
        setImageUrl(res?.data?.data);
      })
      .catch((e) => console.log("API doesent Work", e));
  }, [refresh]);

  const imageUpload = () => {
    const payload = new FormData();
    payload.append("photo", selectedImage);
    axios
      .patch(`${Url()}/api/v1/users/updateMe`, payload, {
        headers,
      })
      .then((res) => {
        changeIMage(res);
      })
      .catch((e) => console.log("API doesent Work", e));
  };

  const getApires = (e) => {
    getrefresh(!refresh);
  };

  return (
    <>
      <div className="updateRight">
        <form>
          <div>
            <div style={{ margin: "0px 0px" }}>
              <label style={{ fontSize: "14px", fontWeight: " bold" }}>
                User Name
              </label>
              <div>
                <TextField
                  style={{ width: "300px" }}
                  disabled
                  size="small"
                  id="outlined-disabled"
                  label={imageUrl?.username}
                />
              </div>
            </div>
            <div style={{ margin: "10px 0px" }}>
              <label style={{ fontSize: "14px", fontWeight: " bold" }}>
                Email
              </label>
              <div>
                <TextField
                  style={{ width: "300px" }}
                  disabled
                  size="small"
                  id="outlined-disabled"
                  label={imageUrl?.email}
                />
              </div>
            </div>
            <div style={{ margin: "10px 0px" }}>
              <label style={{ fontSize: "14px", fontWeight: " bold" }}>
                First Name
              </label>
              <div>
                <TextField
                  style={{ width: "300px" }}
                  disabled
                  size="small"
                  id="outlined-disabled"
                  label={imageUrl?.firstName}
                />
              </div>
            </div>
            <div style={{ margin: "10px 0px" }}>
              <label style={{ fontSize: "14px", fontWeight: " bold" }}>
                Last Name
              </label>
              <div>
                <TextField
                  style={{ width: "300px" }}
                  disabled
                  size="small"
                  id="outlined-disabled"
                  label={imageUrl?.lastName}
                />
              </div>
            </div>
            {/* <div style={{ margin: "10px 0px" }}>
              <label style={{ fontSize: "14px", fontWeight: " bold" }}>
                Website
              </label>
              <div>
                <TextField
                  style={{ width: "300px" }}
                  disabled
                  size="small"
                  id="outlined-disabled"
                  label={imageUrl?.website ? imageUrl?.website : "empty"}
                />
              </div>
            </div> */}
            <div style={{ margin: "10px 0px" }}>
              <label style={{ fontSize: "14px", fontWeight: " bold" }}>
                Role
              </label>
              <div>
                <TextField
                  style={{ width: "300px" }}
                  disabled
                  size="small"
                  id="outlined-disabled"
                  label={imageUrl?.role}
                />
              </div>
            </div>
          </div>
          <Addusers responseData={getApires} userData={imageUrl} />
        </form>
        <div className="profileImage">
          <div className="uploadImg">
            {imageUrl ? (
              <Avatar
                alt={imageUrl?.username.toUpperCase()}
                src={imageUrl?.photo}
                sx={{ width: 150, height: 150, border: "1px solid white" }}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "150px",
                  height: "150px",
                  borderRadius: "200px",
                  border: "1px solid white",
                }}
              >
                <CircularProgress />
              </div>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <label htmlFor="file">
                <CameraAlt style={{ color: "green", cursor: "pointer" }} />
              </label>
              <input
                type="file"
                onChange={getimage}
                id="file"
                style={{ display: "none" }}
              />

              <input
                type="submit"
                style={{ color: "green !important" }}
                onClick={imageUpload}
                value="upload"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
