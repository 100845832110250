/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */

import React, { useEffect, useState } from "react";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import "./ProjectList.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { LinearProgress, Menu, TablePagination } from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";
import HttpApis from "../../../URL/ServicePms";
import EditProjectModal from "./EditProjectModal";

const currencies = [
  {
    value: "Completed",
  },
  {
    value: "Pending",
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Sidetable({ Button, changeValue }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currency, setCurrency] = useState(null);
  const [search, setSearch] = useState();
  const [editProjectFlag, setEditProjectFlag] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };
  const [ids, setIds] = useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setIds(row);
  };

  let [getdata, setgetdata] = useState(null);

  useEffect(() => {
    getAllProjects();
  }, [changeValue, currency, editProjectFlag]);

  const getAllProjects = async () => {
    const response = currency
      ? await HttpApis.getAllProjectsApi(currency)
      : await HttpApis.getProjects();
    setgetdata(response.data.data.projects);
  };

  const deleteProject = async (e, rowid) => {
    if (confirm("Delete this project !")) {
      await HttpApis.deleteProjectApi(rowid).then(() => {
        toast("Deleted sucessfully");
        getAllProjects();
      });
    }
  };
  const handlePopClose = (data) => {
    setOpenModal(data);
  };
  const handleEdit = (data) => {
    setEditProjectFlag(!editProjectFlag);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleCloseMenuPopup = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="dashboard_table">
        <div className="table_heading">
          <div className="table_heading_name">Project List</div>
          <span className="button_AddProject"> {Button}</span>
        </div>
        <div className="table_box">
          <div style={{ display: "flex", padding: "0.8rem 1.3rem 0.5rem" }}>
            <div>
              <TextField
                style={{ width: "250px" }}
                id="outlined-select-currency"
                select
                size="small"
                label="Status"
                value={currency || ""}
                onChange={handleChange}
              >
                {currencies.map((option) => (
                  <MenuItem value={option.value}>{option.value}</MenuItem>
                ))}
              </TextField>
            </div>
            <div style={{ marginLeft: "40px" }}>
              <Box
                sx={{
                  width: 500,
                  maxWidth: "100%",
                }}
              >
                <TextField
                  fullWidth
                  size="small"
                  style={{ width: "377px" }}
                  label="Search"
                  id="fullWidth"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Box>
            </div>
          </div>
          <Paper
            sx={{ width: "100%", overflow: "hidden", padding: "0rem 1rem" }}
          >
            <TableContainer
              style={{
                marginTop: "20px",
                maxHeight: "400px",
              }}
              component={Paper}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead style={{ backgroundColor: "red" }}>
                  <TableRow>
                    <StyledTableCell>Project Name</StyledTableCell>
                    <StyledTableCell align="right">Category</StyledTableCell>
                    <StyledTableCell align="right">
                      Project Manager
                    </StyledTableCell>
                    <StyledTableCell align="right">Start Date</StyledTableCell>
                    <StyledTableCell align="right">Due Date </StyledTableCell>
                    <StyledTableCell align="right">Status</StyledTableCell>
                    <StyledTableCell align="right">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                {getdata ? (
                  <TableBody>
                    {getdata.length !== 0 ? (
                      getdata
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .filter((row) => {
                          if (!search) {
                            return row;
                          } else if (
                            row.name
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          ) {
                            return row;
                          }
                        })

                        .map((row) => {
                          const fydate = moment(
                            row.startDate?.split("T")[0]
                          ).format("DD/MM/YYYY");
                          const ldate = moment(
                            row.dueDate?.split("T")[0]
                          ).format("DD/MM/YYYY");

                          return (
                            <StyledTableRow
                              key={row.name}
                            >
                              <StyledTableCell component="th" scope="row">
                                {row.name}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {row.category?.name}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {row.projectManager}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {fydate}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {ldate}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {row.status}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <div>
                                  <MoreVertIcon
                                    id="demo-positioned-button"
                                    aria-controls={
                                      open ? "demo-positioned-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    onClick={(e) => handleClick(e, row)}
                                    cursor="pointer"
                                  />

                                  <Menu
                                    id="demo-positioned-menu"
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleCloseMenuPopup}
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                  >

                                    <MenuItem
                                      onClick={() => {
                                        setOpenModal(true);
                                        setAnchorEl(null);
                                      }}
                                    >
                                      Edit
                                    </MenuItem>

                                    <MenuItem
                                      onClick={(e) => {
                                        deleteProject(e, ids);
                                      }}
                                    >
                                      Delete
                                    </MenuItem>
                                  </Menu>
                                </div>                
                                {openModal && (
                                  <EditProjectModal
                                    opens={openModal}
                                    setOpens={handlePopClose}
                                    row={ids}
                                    updateEditTask={getAllProjects}
                                  />
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div className="empty-state__message">
                            No records has been added yet.
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                ) : (
                  <StyledTableCell colSpan={8}>
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress />
                    </Box>
                  </StyledTableCell>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={getdata?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </>
  );
}
