import React, { useState } from "react";
import "./loginpage.css";
import { useNavigate } from "react-router";
import Sidebar from "../../components/sideBar/sidebar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HttpsRoundedIcon from "@mui/icons-material/HttpsRounded";
import PersonIcon from "@mui/icons-material/Person";
import { Button, Card } from "@mui/material";
import { Link } from "react-router-dom";
import ForgotAndLoginRoute from "./ForgotPassword";
import HttpApis from "../../URL/ServicePms";

export default function Loginpage() {
  let [getsecurity, setsecurity] = useState({
    email: "",
    password: "",
  });
  const [forgot, setForgot] = useState(false);
  const getToken = localStorage.getItem("TOKEN");

  let getdatas = (e) => {
    setsecurity({ ...getsecurity, [e.target.name]: e.target.value });
  };

  const push = useNavigate();
  const LoginPage = async () => {
    try {
      const response = await HttpApis.loginPage(getsecurity);
      localStorage.setItem("TOKEN", response.data.token);
      localStorage.setItem("USER", JSON.stringify(response.data.data.user));

      push("/");
    } catch (error) {
      toast.error(<span> Invalid username or password</span>);
      console.log("error:", error);
    }
  };

  return (
    <>
      {!forgot ? (
        <>
          {!getToken ? (
            <div className="align">
              <div className="grid">
                <Card
                  sx={{
                    width: "650px",
                    height: "560px",
                    background: "#FFFFFF",
                    boxShadow: "8px 8px 15px rgba(0, 0, 0, 0.34)",
                    borderRadius: "28px",
                    // paddingLeft: "2rem",
                    paddingTop: "1rem",
                  }}
                >
                  <form className="form login">
                    <img
                      src="./company logo.svg"
                      alt="logo"
                      style={{
                        display: "flex",
                        fontSize: "20px",
                        paddingTop: "1rem",
                        paddingLeft: "14rem",
                      }}
                    />
                    <div className="login__name">
                      <p>Login</p>
                    </div>
                    <div className="form__field">
                      <label htmlFor="login__username">
                        <PersonIcon />
                      </label>
                      <input
                        name="email"
                        onChange={getdatas}
                        autoComplete="email"
                        id="login__email"
                        type="text"
                        className="form__input"
                        placeholder="Email"
                        required
                        value={getsecurity.email}
                      />
                    </div>
                    <div className="form__field">
                      <label htmlFor="login__username">
                        <HttpsRoundedIcon />
                      </label>
                      <input
                        value={getsecurity.password}
                        onChange={getdatas}
                        name="password"
                        id="login__password"
                        type="password"
                        className="form__input"
                        placeholder="Password"
                        required
                      />
                    </div>
                    <div className="login_button">
                      <Button
                        variant="contained"
                        onClick={() => LoginPage()}
                        sx={{ width: "15rem" }}
                      >
                        Login
                      </Button>
                    </div>

                    <div className="forgotPassword">
                      <Link
                        to="/resetpassword"
                        style={{ color: "blue" }}
                        onClick={() => setForgot(true)}
                      >
                        Forgot Password
                      </Link>
                    </div>
                  </form>
                </Card>
              </div>
            </div>
          ) : (
            <Sidebar />
          )}
        </>
      ) : (
        <ForgotAndLoginRoute />
      )}
    </>
  );
}
