import React from 'react'
import Layout from '../../Layout'

export default function Dummy() {
  return (
    <>
    <Layout>
        <div>Portal</div>
    </Layout>
    </>
  )
}
