import axios from "axios";
import { cors } from "./Cors";
import { toast } from "react-toastify";
export default class Request {
  constructor(headers) {
    cors();
    this.http = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      withCredentials: false,
    });

    this.http.interceptors.request.use(async (config) => {
      let token = localStorage.getItem("TOKEN");
      if (headers?.responseType) {
        config.responseType = headers?.responseType;
      }
      config.headers = {
        Authorization: token ? `Bearer ${token}` : "",
        // "Content-Type": "application/json",
        ...headers,
      };
      return config;
    });

    this.http.interceptors.response.use(
      async (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        if (error.response) {
          // forbidden: 403
          // unauthrized: 401
          // not found: 400
          if (error.response.status && error.response.status === 403) {
            return Promise.reject(error.response.data);
          } else if (
            error.response.status &&
            (error.response.status === 401 || error.response.status === 415) &&
            !originalRequest._retry
          ) {
            // retry token,OR acquire token again
          } else {
            let errorData = error?.response?.data;
            if (error?.request?.responseType === "blob") {
              errorData = JSON.parse(await error.response.data.text());
              if (error.response.status === 500) {
                errorData.displayFailMessage =
                  "We're sorry, but a program error has occurred. Please contact NTN support";
              }
            }

            if (errorData) {
              if (errorData?.displayFailMessage?.length > 1) {
                toast.error(errorData.displayFailMessage);
              } else {
                toast.error(errorData.errorDetail);
              }
            }
            return Promise.reject(errorData);
          }
        } else if (error.request) {
          return Promise.reject(error.request);
        } else {
          return Promise.reject(error);
        }
      }
    );
    for (const method of ["get", "post", "put", "delete", "patch"]) {
      this[method] = this.http[method];
    }
  }
}