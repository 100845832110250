/* eslint-disable no-unused-vars */

import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import "./adduser.css";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import HttpApis from "../../../URL/ServicePms";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const useStyle = makeStyles({
  component: {
    backgroundColor: "#00B1E6 !important",
    color: "white !important",
    padding: "0.5rem 2.3rem !important",
    borderRadius: "5px",
  },
  sidebar: {
    color: "white",
    margin: "0px",
  },
});

function Adduser({ sidebar, getProps }, ref) {
  const classes = useStyle();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [editId, setEdit] = useState([]);
  const handleClickOpen = () => {
    setOpen(true);
    inputValue.username = "";
    inputValue.reportingManager = "";
    inputValue.email = "";
    inputValue.firstName = "";
    inputValue.lastName = "";
    inputValue.role = "";
    inputValue.website = "";
    inputValue.password = "";
    inputValue.passwordConfirm = "";
  };

  let [inputValue, setinputValue] = useState({
    username: "",
    reportingManager: "",
    email: "",
    firstName: "",
    lastName: "",
    website: "",
    role: "",
    password: "",
    passwordConfirm: "",
  });

  const datafield = (e) => {
    const { name, value } = e.target;
    const number = /[0-9]/;
    if (
      number.test(e.target.value) &&
      e.target.name == "password" &&
      e.target.name == "passwordConfirm"
    ) {
      e.target.value = "";
    } else {
      setinputValue({ ...inputValue, [name]: value });
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (inputValue.password !== inputValue.passwordConfirm) {
      alert("Password doesn't match");
    } else {
      try {
        await HttpApis.addUser(inputValue)
          .then((e) => {
            inputValue.username = "";
            inputValue.reportingManager = "";
            inputValue.email = "";
            inputValue.firstName = "";
            inputValue.lastName = "";
            inputValue.role = "";
            inputValue.website = "";
            inputValue.password = "";
            inputValue.passwordConfirm = "";
            getProps(e);
            toast("Add user successfully");
          })
          .catch((e) => {
            inputValue.username = "";
            inputValue.email = "";
            inputValue.firstName = "";
            inputValue.lastName = "";
            inputValue.role = "";
            inputValue.website = "";
            inputValue.password = "";
            inputValue.reportingManager = "";
            inputValue.passwordConfirm = "";
            toast("invalid email ");
          });
      } catch (err) {}
    }

    setOpen(false);
  };

  return (
    <div>
      <Button
        className={sidebar ? classes.sidebar : classes.component}
        style={{ padding: "0px", backgroundColor: "transparent" }}
        onClick={handleClickOpen}
      >
        Add User
      </Button>
      <BootstrapDialog onClose={handleClose} open={open}>
        <BootstrapDialogTitle
          style={{ width: "60rem !important", textAlign: "center" }}
          onClose={handleClose}
        >
          <h4> Add User</h4>
        </BootstrapDialogTitle>

        <form
          style={{ padding: " 0rem 3.5rem ", minWidth: "60rem !important" }}
          onSubmit={handleSubmit}
          required
        >
          <fieldset>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="User_Name">
                <label className="txtlabel" for="name">
                  User Name
                </label>
                <input
                  sx={{ display: "flex" }}
                  onChange={datafield}
                  className="txtfield"
                  type="text"
                  id="name"
                  name="username"
                  value={inputValue?.username}
                  required
                />
              </div>
            </div>
            <div>
              <label className="txtlabel" for="name">
                Email
              </label>
              <input
                onChange={datafield}
                className="txtfield"
                value={inputValue?.email}
                type="text"
                id="name"
                name="email"
                required
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <label className="txtlabel" for="name">
                  First Name
                </label>
                <input
                  onChange={datafield}
                  className="txtfield"
                  type="text"
                  id="name"
                  name="firstName"
                  value={inputValue?.firstName}
                  required
                />
              </div>

              <div>
                <label className="txtlabel" for="name">
                  Last Name
                </label>
                <input
                  onChange={datafield}
                  className="txtfield"
                  type="text"
                  id="name"
                  name="lastName"
                  value={inputValue?.lastName}
                  required
                />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div sx={{ paddingTop: "2rem !important" }}>
                <label className="txtlabel" for="name">
                  Role
                </label>
                <select
                  onChange={datafield}
                  name="role"
                  id="cars"
                  className="selectfield"
                  required
                  value={inputValue?.role}
                >
                  <option hidden>Select</option>
                  <option>Admin</option>
                  <option>PM</option>
                  <option>Developer</option>
                  <option>Tester</option>
                </select>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div sx={{ paddingTop: "10rem !important" }}>
                <label className="txtlabel" for="name">
                  Password
                </label>
                <input
                  className="txtfield"
                  type="password"
                  id="name"
                  name="password"
                  value={inputValue?.password}
                  onChange={datafield}
                  required
                />
              </div>
              <div style={{ marginLeft: "60px" }}>
                <label className="txtlabel" for="name">
                  Confirm Password
                </label>
                <input
                  className="txtfield"
                  type="password"
                  id="name"
                  name="passwordConfirm"
                  value={inputValue?.passwordConfirm}
                  onChange={datafield}
                  required
                />
              </div>
            </div>
            <div className="button_s">
              <input
                type="button"
                value="Cancel"
                className="cancel_button"
                cursor="pointer"
                onClick={() => {
                  navigate("/All-Users");
                  setOpen(false);
                }}
              />
              <input type="submit" value="Submit" />
            </div>
          </fieldset>
        </form>
      </BootstrapDialog>
    </div>
  );
}
export default Adduser;
