import { _get, _post, _delete, _patch } from "./HttpMethod";
class HttpApis {
  loginPage(params) {
    return _post(`/users/login`, params);
  }

  getUsers() {
    return _get(`/users`);
  }
  getProjects(params) {
    return _get(`/projects`, params);
  }

  completedProjects(params) {
    return _get(`/projects?status=Completed`, params);
  }

  activeProjects(params) {
    return _get(`/projects?status=Pending`, params);
  }

  addUser(params) {
    return _post(`/users/signup`, params);
  }

  getAllTaskStatus(params) {
    return _get(`/tasks?currentStatus=${params}`, params);
  }

  getAllTaskStatusUser(params) {
    return _get(`/tasks?user=${params}`, params);
  }

  getDateFilteredTask(params) {
    return _get(`/tasks?dateFilterType=${params}`, params);
  }
  getUserFilteredTask(params) {
    return _get(`/tasks?user=${params}`, params);
  }

  deleteTaskApi(params) {
    return _delete(`/tasks/${params}`, params);
  }

  editTaskApi(params) {
    return _patch(`/tasks/${params}`, params);
  }

  deleteProjectApi(params) {
    return _delete(`/projects/${params}`, params);
  }

  getAllProjectsApi(params) {
    return _get(`/projects?status=${params}`, params);
  }
  
  // getAllProjectApi(params) {
  //   return _get(`/projects`, params);
  // }

  deleteUserProject(params) {
    return _delete(`/users/${params}`, params);
  }

  // usersApi(params) {
  //   return _get(`/users`, params);
  // }

  addCategories(params) {
    return _post(`/category`, params);
  }

  postProjectApi(params) {
    return _post(`/projects`, params);
  }

  addTasksProjectAPi(params) {
    return _post(`/tasks`, params);
  }

  editProjectCategory(params) {
    return _patch(`/projects/${params}`, params);
  }

  projectById(params) {
    return _patch(`/projects/${params}`, params);
  }

  categoryById(params) {
    return _patch(`/category/${params}`, params);
  }

  getAllCategory() {
    return _get(`/category`);
  }

  deleteCategoryByRowId(params) {
    return _delete(`/category/${params}`, params);
  }

  categoryPatchById(params) {
    return _patch(`/category/${params}`, params);
  }

  userForgetPassword(params){
    return _post(`users/forgotPassword`, params);
  }

  editUser(params){
    return _patch(`/users/${params._id}`,params);
  }
}
export default new HttpApis();
