/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/heading-has-content */

import { useEffect, useState } from "react";
import "./dashboard.css";
import { CircularProgress } from "@mui/material";
import HttpApis from "../../URL/ServicePms";
import DashboardTable from "../../components/Table/dashBoardTable";

export default function Dashboard() {
  let [getuserlength, setgetuserlength] = useState(0);
  let [getprojectlength, setgetprojectlength] = useState(0);
  let [getcompletelength, setgetcompletelength] = useState(0);
  let [getactivelength, setgetactivelength] = useState(0);
  let [user, setUser] = useState("");

  useEffect(() => {
    const userData = async () => {
      const response = await HttpApis.getUsers();
      setgetuserlength(response.data.results);
    };
    userData();
  }, []);

  useEffect(() => {
    const projectData = async () => {
      const response = await HttpApis.getProjects();
      setgetprojectlength(response.data.results);
    };
    projectData();
  }, []);

  useEffect(() => {
    const CompletedProjects = async () => {
      const response = await HttpApis.completedProjects();
      setgetcompletelength(response.data.results);
    };
    CompletedProjects();
  }, []);

  useEffect(() => {
    const ActiveProjects = async () => {
      const response = await HttpApis.activeProjects();
      setgetactivelength(response.data.results);
    };
    ActiveProjects();
  }, []);

  useEffect(() => {
    let user = localStorage.getItem("USER");
    let test = JSON.parse(user);

    setUser(test.username);
  }, []);

  return (
    <>
      <div className="dashboard_box">
        <div className="dashboard_useractivity">
          <div className="useractive_box">
            <div className="useractive_innerbox">
              <h6 className="box_title">Users</h6>
              {getuserlength || getuserlength == 0 ? (
                <h1 className="box_number counterup">{getuserlength}</h1>
              ) : (
                <CircularProgress disableShrink />
              )}
            </div>
          </div>

          <div className="useractive_box">
            <div className="useractive_innerbox">
              <h6 className="box_title">Total Projects</h6>
              {getprojectlength || getprojectlength == 0 ? (
                <h1 className="box_number counterup">{getprojectlength}</h1>
              ) : (
                <CircularProgress disableShrink />
              )}
            </div>
          </div>

          <div className="useractive_box">
            <div className="useractive_innerbox">
              <h6 className="box_title">Active Projects</h6>
              {getactivelength || getactivelength == 0 ? (
                <h1 className="box_number counterup">{getactivelength}</h1>
              ) : (
                <CircularProgress disableShrink />
              )}
            </div>
          </div>

          <div className="useractive_box">
            <div className="useractive_innerbox">
              <h6 className="box_title">Completed Projects</h6>
              {getcompletelength || getcompletelength == 0 ? (
                <h6
                  className="box_number counterup"
                  data-number={getcompletelength}
                >
                  {getcompletelength}
                </h6>
              ) : (
                <CircularProgress disableShrink />
              )}
            </div>
          </div>
        </div>
        <DashboardTable />
      </div>
    </>
  );
}
