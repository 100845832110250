import React, { useState } from 'react'
// import Topbar from '../components/topbar';
import PersistentDrawerLeft from '../components/sideBar/sidebar';

export default function Layout({children}) {
  const [data, getData] = useState();
  return (
    <>
   {/* <Topbar /> */}
   <PersistentDrawerLeft />
    </>
  );
}
