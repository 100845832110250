import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
// import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";
import Url from "../../../URL/url";
import HttpApis from "../../../URL/ServicePms";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const EditProjectModal = (props) => {
  const {
    opens,
    row: {
      _id,
      name,
      projectManager,
      category,
      projectDetails,
      startDate,
      dueDate,
      status,
    },
    setOpens,
    updateEditTask,
  } = props;
  const start = moment(startDate.split("T")[0]).format("YYYY-MM-DD");
  const due = moment(dueDate.split("T")[0]).format("YYYY-MM-DD");
  const [editProjectForm, setEditProjectForm] = useState({});
  let [getcategory, setcategory] = useState([]);
  useEffect(() => {
    const allCategories = async () => {
      const response = await HttpApis.getAllCategory();
      setcategory(response.data.data);
    };
    allCategories();
  }, []);

  useEffect(() => {
    const Obj = {
      name: name,
      projectDetails: projectDetails,
      category: category,
      projectManager: projectManager,
      startDate: start,
      dueDate: due,
      status: status,
    };
    setEditProjectForm(Obj);
  }, []);

  const handleClose = () => {
    setOpens(false);
  };

  const setEditProjectData = (e) => {
    let name = e.target?.name;
    let value = e.target?.value;
    setEditProjectForm({ ...editProjectForm, [name]: value });
  };

  const headers = { Authorization: `Bearer ${localStorage.getItem("TOKEN")}` };

  const editProject = async (e) => {
    e.preventDefault();
    await axios
      .patch(`${Url()}/api/v1/projects/${_id}`, editProjectForm, { headers })
      .then((e) => {
        toast("Project Edit successfully");
        props.updateEditTask();
      });
    handleClose();
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={opens}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{ textAlign: "center" }}
        >
          <h4> Edit </h4>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography style={{ width: "520px" }} gutterBottom>
            <form
              style={{
                padding: " 0rem 3.5rem ",
                minWidth: "60rem !important",
              }}
              required
              onSubmit={editProject}
            >
              <fieldset>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="User_Name">
                    <label className="txtlabel" for="name">
                      Project Name
                    </label>
                    <input
                      sx={{ display: "flex" }}
                      onChange={setEditProjectData}
                      className="txtfield"
                      type="text"
                      id="name"
                      name="name"
                      defaultValue={name}
                      required
                    />
                  </div>
                </div>
                <div>
                  <label className="txtlabel" for="name">
                    Project Manager
                  </label>
                  <input
                    onChange={setEditProjectData}
                    className="txtfield"
                    defaultValue={projectManager}
                    type="text"
                    id="name"
                    name="projectManager"
                    required
                  />
                </div>
                <label className="txtlabel" for="email">
                  Category
                </label>
                <select
                  type="text"
                  onChange={setEditProjectData}
                  className="categoryField"
                  name="category"
                  id="cars"
                  required
                >
                  <option value="none" selected disabled hidden>
                    {category?.name}
                  </option>
                  {getcategory.map((a) => (
                    <>
                      <option value={a._id}>{a.name}</option>
                    </>
                  ))}
                </select>
                <label className="txtlabel" for="email">
                  Status
                </label>
                <select
                  type="text"
                  onChange={setEditProjectData}
                  className="categoryField"
                  name="status"
                  id="status"
                  defaultValue={status}
                  required
                >
                  <>
                    <option value="select" hidden>
                      select
                    </option>
                    <option value="Completed">Completed</option>
                    <option value="Pending">Pending</option>
                  </>
                </select>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "6px",
                  }}
                >
                  <div>
                    <label for="startDate">Start Date</label>
                    <input
                      onChange={setEditProjectData}
                      className="datefield"
                      type="date"
                      id="date"
                      name="startDate"
                      defaultValue={start}
                      required
                    />
                  </div>
                  <div>
                    <label for="Enddate">End Date</label>
                    <input
                      onChange={setEditProjectData}
                      className="datefield"
                      type="date"
                      id="date"
                      defaultValue={due}
                      name="dueDate"
                      required
                    />
                  </div>
                </div>
                <div className="button_s">
                  <input
                    type="button"
                    value="Cancel"
                    className="cancel_button"
                    cursor="pointer"
                    onClick={handleClose}
                  />
                  <input type="submit" value="Submit" />
                </div>
              </fieldset>
            </form>
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default EditProjectModal;
