/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable no-dupe-keys */

import React, { useEffect, useState } from "react";
import "./sidebar.css";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import GroupIcon from "@mui/icons-material/Group";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import ListItemButton from "@mui/material/ListItemButton";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { Routes, Route, NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AssignmentIcon from "@mui/icons-material/Assignment";
import axios from "axios";
import Url from "../../URL/url";
import Adduser from "../ManageUsers/AddUsers/adduser";
import Inputfield from "../ManageProjects/AddProject/AddProjectModal";
import Addfield from "../ManageTask/AddTask/addTaskComponent";
import Addcategories from "../ManageCategories/addcategories/addcategories";
import CategoryIcon from "@mui/icons-material/Category";
import Routing from "../SidebarRouting";
import Topbar from "../topbar";
import DrawersHeader from "../drawerheader";

const drawerWidth = 285;
// eslint-disable-next-line no-unused-vars
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft(props) {
  const [addCategory, setaddCategory] = useState(false);

  const setbackfun = (e) => {
    setaddCategory(!addCategory);
  };
  const addUserData = () => {
    setaddCategory(!addCategory);
  };

  let [ref, getreferse] = useState(false);
  const [open, setOpen] = useState(true);

  // 1 first slider************************************
  const [project, setproject] = useState(false);
  const fhandleClick = () => {
    setproject(!project);
  };

  const [user, setuser] = useState(false);
  const shandleClick = () => {
    setuser(!user);
  };

  const [setting, setsetting] = useState(false);
  const thandleClick = () => {
    setsetting(!setting);
  };

  const [categories, setcategories] = useState(false);
  const cohandleClick = () => {
    setcategories(!categories);
  };

  const [gettask, settask] = useState(false);
  const [getManageTask, setManageTask] = useState(false);
  const getManageHandleClick = () => {
    setManageTask(!getManageTask);
  };
  const fohandleClick = () => {
    settask(!gettask);
  };
  const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
      ...(open && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: "40px",
      }),
    })
  );

  let mylocation = useLocation();
  const a = mylocation.pathname.split("/");
  let [headername, setHeaderName] = useState(a[1]);
  const [imageUrl, setImageUrl] = useState(null);
  useEffect(() => {
    setHeaderName(a[1]);
    let user = localStorage.getItem("USER");
    let test = JSON.parse(user);
  }, [a]);

  const token = localStorage.getItem("TOKEN");
  const headers = { Authorization: `Bearer ${token}` };
  let [isactive, setactive] = useState();
  const shu = "PM ";

  const [role, setRole] = useState("");

  // JSON.parse(localStorage.getItem("USER"));
  useEffect(() => {
    axios
      .get(`${Url()}/api/v1/users/me`, {
        headers,
      })
      .then((res) => {
        setImageUrl(res?.data?.data);
      })
      .catch((e) => console.log("API doesent Work", e));
  }, [ref]);

  useEffect(() => {
    let localUserData = localStorage.getItem("USER");
    setRole(JSON.parse(localUserData)?.role);
  });

  useEffect(() => {
    axios
      .get(`${Url()}/api/v1/tasks/${shu.id}`, { headers })
      .then((e) => setactive(e));
  }, []);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Topbar nameTop={a[1]} />
        <Drawer
          sx={{
            width: "290px",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: "330px",
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawersHeader />
          <Divider />
          <List className="drower_list">
            <NavLink
              style={{ textDecoration: "none" }}
              activeclassname="selected"
              to="/"
            >
              <ListItem
                className="list_items"
                onClick={() => {
                  setHeaderName(a[1]);
                  // setNameTop("Dashboard");
                }}
                button
              >
                <ListItemIcon
                  style={{ color: "honeydew", paddingLeft: "10px" }}
                  className="list_icon"
                >
                  <DashboardOutlinedIcon />
                </ListItemIcon>
                <ListItemText className="list_text" primary="Dashboard" />
              </ListItem>
            </NavLink>
            {role === "Admin" ? (
              <>
                {/* {.......................Manage Project.....................} */}
                <NavLink
                  style={{ textDecoration: "none" }}
                  activeclassname="selected"
                  to="/Projects"
                >
                  <ListItemButton className="list_items" onClick={fhandleClick}>
                    <ListItemIcon
                      style={{ color: "honeydew", paddingLeft: "10px" }}
                      className="list_icon"
                    >
                      <ManageAccountsIcon />
                    </ListItemIcon>
                    <ListItemText
                      className="list_text"
                      primary="Manage Project"
                    />
                    {project ? (
                      <ExpandLess style={{ color: "white" }} />
                    ) : (
                      <ExpandMore style={{ color: "white" }} />
                    )}
                  </ListItemButton>

                  <Collapse in={project} timeout="auto" unmountOnExit>
                    <List
                      sx={{ paddingLeft: "3rem" }}
                      component="div"
                      className="liststyle"
                      disablePadding
                    >
                      <ListItemButton
                        onClick={() => {
                          setHeaderName(a[1]);
                        }}
                        sx={{ pl: 4 }}
                      >
                        <ListItemText
                          className="list_text"
                          primary="Project List"
                        />
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => {
                          setHeaderName(a[1]);
                        }}
                        sx={{ pl: 4 }}
                      >
                        <ListItemText
                          className="list_text"
                          primary={
                            <Inputfield postData={addUserData} sidebar="diff" />
                          }
                        />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </NavLink>

                {/* {......................Manage Employeee.........................} */}
                <NavLink
                  style={{ textDecoration: "none" }}
                  activeclassname="selected"
                  to="/All-Users"
                >
                  <ListItemButton className="list_items" onClick={shandleClick}>
                    <ListItemIcon
                      style={{ color: "honeydew", paddingLeft: "10px" }}
                      className="list_icon"
                    >
                      <GroupIcon />
                    </ListItemIcon>
                    <ListItemText
                      className="list_text"
                      primary="Manage Employee"
                    />
                    {user ? (
                      <ExpandLess style={{ color: "white" }} />
                    ) : (
                      <ExpandMore style={{ color: "white" }} />
                    )}
                  </ListItemButton>

                  <Collapse in={user} timeout="auto" unmountOnExit>
                    <List
                      sx={{ paddingLeft: "3rem" }}
                      component="div"
                      className="liststyle"
                      disablePadding
                    >
                      <ListItemButton
                        onClick={() => {
                          setHeaderName(a[1]);
                        }}
                        sx={{ pl: 4 }}
                      >
                        <ListItemText
                          className="list_text"
                          primary="Employee List"
                        />
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => {
                          setHeaderName(a[1]);
                        }}
                        sx={{ pl: 4 }}
                      >
                        <ListItemText
                          className="list_text"
                          primary={
                            <Adduser getProps={addUserData} sidebar="diff" />
                          }
                        />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </NavLink>

                {/* 3.........Dropdown-Menue..................................  */}

                <NavLink
                  style={{ textDecoration: "none" }}
                  activeclassname="selected"
                  to="/My-Task"
                >
                  <ListItemButton
                    className="list_items"
                    onClick={getManageHandleClick}
                  >
                    <ListItemIcon
                      style={{ color: "honeydew", paddingLeft: "10px" }}
                      className="list_icon"
                    >
                      <AssignmentIcon />
                    </ListItemIcon>
                    <ListItemText className="list_text" primary="Manage Task" />
                    {gettask ? (
                      <ExpandLess style={{ color: "white" }} />
                    ) : (
                      <ExpandMore style={{ color: "white" }} />
                    )}
                  </ListItemButton>
                  <Collapse in={getManageTask} timeout="auto" unmountOnExit>
                    <List
                      sx={{ paddingLeft: "3rem" }}
                      component="div"
                      className="liststyle"
                      disablePadding
                    >
                      <ListItemButton
                        onClick={() => {
                          setHeaderName(a[1]);
                        }}
                        sx={{ pl: 4 }}
                      >
                        <ListItemText
                          className="list_text"
                          primary="List Task"
                        />
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => {
                          setHeaderName(a[1]);
                        }}
                        sx={{ pl: 4 }}
                      >
                        <ListItemText
                          className="list_text"
                          primary={<Addfield getstatus={setbackfun} />}
                        />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </NavLink>

                {/* 4.........Dropdown-Menu..................................  */}

                <NavLink
                  style={{ textDecoration: "none" }}
                  activeclassname="selected"
                  to="/Categories"
                >
                  <ListItemButton
                    className="list_items"
                    onClick={cohandleClick}
                  >
                    <ListItemIcon
                      style={{ color: "honeydew", paddingLeft: "10px" }}
                      className="list_icon"
                    >
                      <CategoryIcon />
                    </ListItemIcon>
                    <ListItemText
                      className="list_text"
                      primary="Manage Categories"
                    />
                    {categories ? (
                      <ExpandLess style={{ color: "white" }} />
                    ) : (
                      <ExpandMore style={{ color: "white" }} />
                    )}
                  </ListItemButton>
                  <Collapse in={categories} timeout="auto" unmountOnExit>
                    <List
                      sx={{ paddingLeft: "3rem" }}
                      component="div"
                      className="liststyle"
                      disablePadding
                    >
                      <ListItemButton
                        onClick={() => {
                          setHeaderName(a[1]);
                        }}
                        sx={{ pl: 4 }}
                      >
                        <ListItemText
                          className="list_text"
                          primary="Categories"
                        />
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => {
                          setHeaderName(a[1]);
                        }}
                        sx={{ pl: 4 }}
                      >
                        <ListItemText
                          className="list_text"
                          primary={<Addcategories callbackfun={setbackfun} />}
                        />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </NavLink>
              </>
            ) : (
              <>
                <NavLink
                  style={{ textDecoration: "none" }}
                  activeclassname="selected"
                  to="/My-Task"
                >
                  <ListItemButton
                    className="list_items"
                    onClick={fohandleClick}
                  >
                    <ListItemIcon
                      style={{ color: "honeydew", paddingLeft: "10px" }}
                      className="list_icon"
                    >
                      <AssignmentIcon />
                    </ListItemIcon>
                    <ListItemText className="list_text" primary="My Task" />
                    {gettask ? (
                      <ExpandLess style={{ color: "white" }} />
                    ) : (
                      <ExpandMore style={{ color: "white" }} />
                    )}
                  </ListItemButton>
                  <Collapse in={gettask} timeout="auto" unmountOnExit>
                    <List
                      sx={{ paddingLeft: "3rem" }}
                      component="div"
                      className="liststyle"
                      disablePadding
                    >
                      <ListItemButton
                        onClick={() => {
                          setHeaderName(a[1]);
                        }}
                        sx={{ pl: 4 }}
                      >
                        <ListItemText
                          className="list_text"
                          primary="List Task"
                        />
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => {
                          setHeaderName(a[1]);
                        }}
                        sx={{ pl: 4 }}
                      >
                        <ListItemText
                          className="list_text"
                          primary={<Addfield getstatus={setbackfun} />}
                        />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </NavLink>
              </>
            )}
            <NavLink
              style={{ textDecoration: "none" }}
              activeclassname="selected"
              to="/Report"
            >
              <ListItem
                className="list_items"
                onClick={() => {
                  setHeaderName(a[1]);
                }}
                button
              >
                <ListItemIcon
                  style={{ color: "honeydew", paddingLeft: "10px" }}
                  className="list_icon"
                >
                  <NoteAltIcon />
                </ListItemIcon>
                <ListItemText
                  className="list_text"
                  primary={"Manage Reports"}
                />
              </ListItem>
            </NavLink>
            <ListItemButton className="list_items" onClick={thandleClick}>
              <ListItemIcon
                style={{ color: "honeydew", paddingLeft: "10px" }}
                className="list_icon"
              >
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText className="list_text" primary="Manage Setting" />
              {setting ? (
                <ExpandLess style={{ color: "white" }} />
              ) : (
                <ExpandMore style={{ color: "white" }} />
              )}
            </ListItemButton>
            <Collapse in={setting} timeout="auto" unmountOnExit>
              <List
                sx={{ paddingLeft: "3rem" }}
                component="div"
                className="liststyle"
                disablePadding
              >
                <NavLink
                  style={{ textDecoration: "none" }}
                  activeclassname="selected"
                  to="/Profile"
                >
                  <ListItemButton
                    onClick={() => {
                      setHeaderName(a[1]);
                    }}
                    sx={{ pl: 4 }}
                  >
                    <ListItemText className="list_text" primary="Profile" />
                  </ListItemButton>
                </NavLink>
                <NavLink
                  style={{ textDecoration: "none" }}
                  activeclassname="selected"
                  to="/changepassword"
                >
                  <ListItemButton
                    onClick={() => {
                      alert(a[1]);
                      setHeaderName(a[1]);
                    }}
                    sx={{ pl: 4 }}
                  >
                    <ListItemText
                      className="list_text"
                      primary="Change Password"
                    />
                  </ListItemButton>
                </NavLink>
              </List>
            </Collapse>
          </List>
          <Divider />
        </Drawer>
        <Main style={{ background: "rgb(241, 243, 246)" }} open={open}>
          <DrawerHeader />
          <Routing />
        </Main>
      </Box>
    </>
  );
}
