import React from "react"
import Resetpassword from "../../components/resetPassword/reset";
import { Route, Routes } from "react-router";

const ForgotAndLoginRoute=()=>{

  return (
    <>
      <Routes>
        <Route path="/resetpassword"element={<Resetpassword/>} />
      </Routes>
    </>
  )
}
export default ForgotAndLoginRoute