import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import "../sideBar/sidebar.css";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function DrawersHeader() {
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
     
    </>
  );
}
