import { Button, Card } from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import "./resetPage.css";
import { Link } from "react-router-dom";
import HttpApis from "../../URL/ServicePms";

const Reset = () => {
  const [email, setEmail] = useState();

  const handleSubmit = async () => {
    try {
      await HttpApis.userForgetPassword({ email });
      toast("Verification mail sent successfully");
    } catch (err) {
      toast("Something went wrong");
    }
  };

  return (
    <>
      <div className="container_main">
        <div className="card_css">
          <Card
            sx={{
              width: "600px",
              height: "540px",
              background: "#FFFFFF",
              boxShadow: "8px 8px 15px rgba(0, 0, 0, 0.34)",
              borderRadius: "28px",
              paddingLeft: "3rem",
              
              // paddingTop: "1rem",
            }}
          >
            <img
              src="./company logo.svg"
              alt="logo"
              style={{
                display: "flex",
                fontSize: "20px",
                paddingTop: "1rem",
                paddingLeft: "10rem",
              }}
            />
            <div className="reset__pass">
              <p>Reset Password</p>
            </div>

            <div className="text">
              <p>
                Please enter your registered email address to receive a link to
                reset password.
              </p>
            </div>
            <div>
              <p className="address">Email Address</p>
            </div>
            <div className="input_field">
              <input
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
                id="login__email"
                type="text"
                className="form__input"
                placeholder="Email"
                required
                value={email}
              />
            </div>
            <div className="button">
              <Button variant="contained" onClick={handleSubmit}>
                submit
              </Button>
            </div>
            <div className="goBackTxt">
              <a href="/" style={{ color: "blue" }}>
                Go back
              </a>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Reset;
