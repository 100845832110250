/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import "./mytask.css";
import Addfield from "../../ManageTask/AddTask/addTaskComponent";
import MyTaskEditBtn from "./myTaskEditBtn";
import { toast } from "react-toastify";
import moment from "moment";
import Modal from "@mui/material/Modal";
import Comment from "../../sideBar/comment";
import HttpApis from "../../../URL/ServicePms";
import { Box, LinearProgress, Menu, TablePagination } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";

const currencies = [
  {
    value: "Pending",
  },
  {
    value: "Completed",
  },
];

const filterOption = [
  {
    value: "today",
  },
  {
    value: "last Week",
  },
  {
    value: "last 30 days",
  },
  {
    value: "Custom Search",
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Mytask(props) {
  let [gettask, setgettask] = useState(null);
  let currentUser = JSON.parse(localStorage.getItem("USER"))._id;
  const [getvalue, setvalue] = useState(null);
  const [search, setSearch] = useState(null);
  const [currency, setCurrency] = useState();
  const [editTask, SetUpdateTask] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [comments, setComments] = useState(false);
  const [ids, setIds] = useState("");

  const handleClose = () => {
      SetUpdateTask(!editTask);
      setAnchorEl(null);
  };

  const update = (e) => {
    SetUpdateTask(!editTask);
    setOpenModal(true);
  };

  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    getAllTask();
  }, [props.resultFunction, getvalue, editTask]);

  const getAllTask = async () => {
    const response = currency
      ? await HttpApis.getAllTaskStatus(currency)
      : await HttpApis.getAllTaskStatusUser(currentUser);
    setgettask(response.data.data);
  };

  const getFilteredTask = async () => {
    const response = filterOption
      ? await HttpApis.getDateFilteredTask(filterOption)
      : await HttpApis.getUserFilteredTask(currentUser);
    setgettask(response.data.data);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deleteTask = async (ids) => {
    if (confirm("Delete this task !")) {
      HttpApis.deleteTaskApi(ids?.id).then(() => {
        toast("Deleted sucessfully");
        getAllTask();
      });
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setIds(row);
  };

  const handlePopClose = (data) => {
    setOpenModal(data);
  };

  const handleCloseMenuPopup = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="dashboard_table">
        <div className="table_heading" style={{ color: "black" }}>
          Task List
          <span className="taskButton">
            <Addfield getstatus={setvalue} />
          </span>
        </div>
        <div style={{ display: "flex", padding: "10px" }}>
          <div>
            <TextField
              style={{
                width: "250px",
                border: "solid #ffffff",
                borderRadius: "1rem",
                backgroundColor: "#ffffff",
              }}
              id="outlined-select-currency"
              select
              size="small"
              label="Status"
              value={currency}
              onChange={(e) => setSearch(e.target.value)}
            >
              {currencies.map((option) => (
                <MenuItem key={option.id} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div style={{ marginLeft: "40px" }}>
            <Box
              sx={{
                width: 300,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                size="small"
                style={{
                  width: "277px",
                  border: "solid #ffffff",
                  borderRadius: "1rem",
                  backgroundColor: "#ffffff",
                }}
                label="Search by Task"
                id="fullWidth"
                onChange={(e) => setSearch(e.target.value)}
              />
            </Box>
          </div>
          <div>
            <TextField
              style={{
                width: "250px",
                border: "solid #ffffff",
                borderRadius: "1rem",
                backgroundColor: "#ffffff",
              }}
              id="outlined-select-currency"
              select
              size="small"
              label="Filter"
              value={currency}
              onChange={(e) => setSearch(e.target.value)}
            >
              {filterOption.map((option) => (
                <MenuItem key={option.id} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div
            style={{
              borderRadius: "2rem",
              paddingTop: "10px",
              marginLeft: "10px",
            }}
          >
            <HighlightOffTwoToneIcon
              onClick={() => {
                setSearch(null);
              }}
            />
          </div>
        </div>
        {search == "Custom Search" ? (
          <div className="datefiel" style={{ padding: "10px" }}>
            <TextField
              id="date"
              label="Start Date"
              type="date"
              name="startdate"
              onChange={handleChange}
              size="small"
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              id="date"
              label="Due Date"
              type="date"
              name="dueDate"
              onChange={handleChange}
              size="small"
              sx={{ width: 220, marginLeft: 2 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        ) : (
          <></>
        )}
        <div className="table_box">
          {comments && (
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Comment handleClose={handleClose} />
            </Modal>
          )}
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer
              sx={{ maxHeight: 380 }}
              style={{ marginTop: "20px", maxHeight: "400px" }}
              component={Paper}
            >
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{ minWidth: 700 }}
              >
                <TableHead>
                  <TableRow className="rowTable">
                    <StyledTableCell
                      sx={{
                        background: "white !important",
                        color: "black !important",
                        fontSize: "1rem",
                      }}
                    >
                      Project Name
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{
                        background: "white !important",
                        color: "black !important",
                        fontSize: "1rem",
                      }}
                    >
                      Task Name
                    </StyledTableCell>

                    <StyledTableCell
                      align="right"
                      sx={{
                        background: "white !important",
                        color: "black !important",
                        fontSize: "1rem",
                      }}
                    >
                      Start Date
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      sx={{
                        background: "white !important",
                        color: "black !important",
                        fontSize: "1rem",
                      }}
                    >
                      Due Date
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      sx={{
                        background: "white !important",
                        color: "black !important",
                        fontSize: "1rem",
                      }}
                    >
                      Estimated Time
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      sx={{
                        background: "white !important",
                        color: "black !important",
                        fontSize: "1rem",
                      }}
                    >
                      Consumed time
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{
                        background: "white !important",
                        color: "black !important",
                        fontSize: "1rem",
                      }}
                    >
                      Status
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      sx={{
                        background: "white !important",
                        color: "black !important",
                        fontSize: "1rem",
                      }}
                    >
                      Action
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                {gettask ? (
                  <TableBody>
                    {gettask.length !== 0 ? (
                      gettask
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .filter((row) => {
                          if (!search) {
                            return row;
                          } else if (
                            row.name
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          ) {
                            return row;
                          } else if (
                            row.currentStatus
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          ) {
                            return row;
                          } else if (
                            row.project?.name
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          ) {
                            return row;
                          } else if (
                            row.startDate
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          ) {
                            return row;
                          }
                        })
                        .map((rows) => {
                          const fydate = moment(
                            rows.startDate?.split("T")[0]
                          ).format("DD/MM/YYYY");
                          const ldate = moment(
                            rows.dueDate?.split("T")[0]
                          ).format("DD/MM/YYYY");

                          return (
                            <StyledTableRow key={rows._id}>
                              <StyledTableCell component="th" scope="row">
                                {rows.project?.name}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {rows.name}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {fydate}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {ldate}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {rows.estimatedHours}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {rows.consumedHours}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {rows.currentStatus}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <div>
                                  <MoreVertIcon
                                    id="demo-positioned-button"
                                    aria-controls={
                                      open ? "demo-positioned-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    onClick={(e) => handleClick(e, rows)}
                                    cursor="pointer"
                                  />

                                  <Menu
                                    id="demo-positioned-menu"
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleCloseMenuPopup}
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                  >
                                    <MenuItem
                                      onClick={() => {
                                        // setAnchorEl(null);
                                        setComments(true);
                                      }}
                                      open={open}
                                    >
                                      Comment
                                    </MenuItem>

                                    <MenuItem
                                      onClick={() => {
                                        setOpenModal(true);
                                        setAnchorEl(null);
                                      }}
                                    >
                                      Edit
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        deleteTask(ids);
                                      }}
                                    >
                                      Delete
                                    </MenuItem>
                                  </Menu>
                                </div>
                                {openModal && (
                                  <MyTaskEditBtn
                                    opens={openModal}
                                    setOpens={handlePopClose}
                                    row={ids}
                                    updateEditTask={update}
                                  />
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div class="empty-state__message">
                            No records has been added yet.
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                ) : (
                  <StyledTableCell colSpan={8}>
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress />
                    </Box>{" "}
                  </StyledTableCell>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={gettask?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              variant="outlined"
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />{" "}
          </Paper>
        </div>
      </div>
    </>
  );
}
