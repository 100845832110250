/* eslint-disable react-hooks/rules-of-hooks */
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, memo } from "react";
import EditIcon from "@mui/icons-material/Edit";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { toast } from "react-toastify";
import Url from "../../../URL/url";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function CategoriesEditBtn(props) {
  const {
    opens,
    row: { name, id, description },
    setOpens,
    updateEditCategory,
  } = props;
  const [editProjectForm, setEditProjectForm] = useState({});
  useEffect(() => {
    const Obj = {
      name: name,
      description: description,
    };
    setEditProjectForm(Obj);
  }, []);

  const handleClose = () => {
    setOpens(false);
  };
  const setUserData = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setEditProjectForm({ ...editProjectForm, [name]: value });
  };
  const headers = { Authorization: `Bearer ${localStorage.getItem("TOKEN")}` };
  const payload = editProjectForm;
  const editProject = (e) => {
    e.preventDefault();
    axios
      .patch(`${Url()}/api/v1/category/${id}`, payload, { headers })
        .then((e) => {
        toast("Category Edit sucessfully");
        handleClose();
        props.updateEditCategory(e);
      });
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={opens}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{ textAlign: "center" }}
        >
          <h4> Edit</h4>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography style={{ width: "520px" }} gutterBottom>
            <form onSubmit={editProject}>
              <div
                className="editField"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>
                  <TextField
                    style={{ width: "250px", height: "40px" }}
                    size="small"
                    id="outlined-helperText"
                    label="Category Name"
                    name="name"
                    onChange={setUserData}
                    defaultValue={name}
                  />
                </div>
                <div>
                  <div>
                    <TextField
                      style={{ width: "250px", height: "40px" }}
                      size="small"
                      id="outlined-helperText"
                      label="Category Description"
                      name="description"
                      onChange={setUserData}
                      defaultValue={description}
                    />
                  </div>
                </div>
              </div>

              <input type="submit" value="Submit" />
            </form>
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}
export default memo(CategoriesEditBtn);
