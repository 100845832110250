import { React, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./addcategories.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import HttpApis from "../../../URL/ServicePms"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Addcategories(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  let [value, setvalue] = useState({
    name: "",
    description: "",
  });

  let catValue = (e) => {
    const num = /[0-9]/;
    if (num.test(e.target.value)) {
      e.target.value = "";
      alert(" Please enter text");
    } else {
      setvalue({ ...value, [e.target.name]: e.target.value });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const handleCancel = () => {
    setOpen(false);
    navigate("/Categories");
  };

  const handleSubmit = async (e) => {
     HttpApis.addCategories(value).then((e) => {
      toast("Add category successfully");
      props.callbackfun(e);
    });
  }

  return (
    <div>
      <Button
        style={{ padding: "0px", color: "white" }}
        onClick={handleClickOpen}
      >
        Add Category
      </Button>
      <BootstrapDialog onClose={handleClose} open={open}>
        <BootstrapDialogTitle
          style={{ width: "600px", textAlign: "end" }}
          onClose={handleSubmit}
        >
          <h4>New Category</h4>
        </BootstrapDialogTitle>

        <div className="formbox">
          <form onSubmit={handleSubmit}>
            <fieldset className="field">
              <label className="txtlabel" for="name">
                Name
              </label>
              <input
                className="txtfield"
                type="text"
                id="name"
                name="name"
                onChange={catValue}
                required
              />

              <div className="textareafield">
                <label className="txtlabel" for="bio">
                  Description
                </label>
                <textarea
                  id="bio"
                  name="description"
                  onChange={catValue}
                  required
                  style={{ paddingBottom: "4rem" }}
                ></textarea>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "1rem",
                  paddingTop: "5rem",
                }}
              >
                <button className="cancelButton" onClick={handleCancel}>
                  Cancel
                </button>
                <input type="submit" value="Add" />
              </div>
            </fieldset>
          </form>
        </div>
      </BootstrapDialog>
    </div>
  );
}
