// import "./App.css";

import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Url from "../../URL/url";
import CloseIcon from "@mui/icons-material/Close";

const Comments = (props) => {
  let token = localStorage.getItem("TOKEN");
  const headers = { Authorization: `Bearer ${token}` };
  const [input, setInput] = useState("");
  const [user, setUser] = useState("");
  const [reply, setReply] = useState("");
  const [total_comments, settotal_comments] = useState([]);
  const [comments, setComments] = useState("");
  const [toggle, istoggle] = useState(false);
  const [replies, setReplies] = useState([]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("USER"));
    setUser(user);
    getAllComment();
  }, []);
  const handleChange = (e) => {
    setComments(e.target.value);
  };
  const getAllComment = () => {
    axios
      .get(`${Url()}/api/v1/comments?task=61b1cc39a3b0371799554438`, {
        headers,
      })
      .then((e) => {
        toast("get particular comment successfull");
        settotal_comments(e.data.data.comments);
      });
  };
  const submit = (e) => {
    e.preventDefault();
    var data = {
      comment: comments,
      user: user.id,
      task: "61b1cc39a3b0371799554438",
    };

    axios.post(`${Url()}/api/v1/comments`, data, { headers }).then((e) => {
      toast("Add comment successfull");
      getAllComment();
    });
  };

  const showReplies = (id, e) => {
    e.stopPropagation();
    showData();
    istoggle(!toggle);
    if (toggle === false) {
      axios
        .get(`${Url()}/api/v1/replies/comment/${id}`, {
          headers,
        })
        .then((e) => {
          toast("get particular Reply");
          setReplies(e.data.data);
        });
    } else {
      setReplies([]);
    }
  };
  const showData = () => <h1>hello</h1>;
  const addReply = (index) => {
    var data = {
      text: comments,
      user: user.id,
      commentId: index,
    };
    axios.post(`${Url()}/api/v1/replies`, data, { headers }).then((e) => {
      toast("Add reply successfull");
      showReplies();
    });
  };
  return (
    <div>
      <div id="comment_app" class="container">
        <div class="navbar navbar-default">
          <div class="navbar-header">
            <a class="navbar-brand">Comments App</a>
          </div>
          <div class="navbar-collapse collapse">
            <ul class="navbar-right navbar-nav nav">
              <li>{/* <a href="#">{total_comments} Comments</a> */}</li>
            </ul>
          </div>
        </div>
        <div>
          <div
            class="row"
            style={{
              background: "white",
              width: "fit-content",
              margin: "0px auto",
            }}
          >
            <form class="col-sm-offset-4" onSubmit={submit}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3>Create Comments</h3>
                <div style={{ paddingTop: "0.3rem" }}>
                  <CloseIcon />
                </div>
              </div>

              <div class="form-group">
                <textarea
                  required="required"
                  placeholder="Your comment"
                  class="form-control"
                  onChange={handleChange}
                ></textarea>
              </div>
              <button class="btn btn-info">Create</button>
              <br />
              <br />
              <legend></legend>
            </form>

            <div class="cols-m-12">
              <div
                class="col-sm-offset-4"
                style={{ height: "300px", overflowY: "scroll" }}
              >
                <div>
                  {total_comments.map((items) => {
                    return (
                      <div
                        style={{
                          background: "#a2e2ef",
                          width: "100vh",
                          padding: "10px",
                          margin: "10px auto",
                          borderRadius: "1rem",
                        }}
                        key={items._id}
                      >
                        <h6>{items.user?.username}</h6>
                        <h5>Comment: {items.comment}</h5>

                        <button
                          onClick={(e) => {
                            showReplies(items._id, e);
                          }}
                          style={{
                            padding: "5px",
                            background: "white",
                            borderRadius: "1rem",
                          }}
                        >
                          {toggle === false ? "Show Replies" : "Hide Replies"}
                        </button>
                        {toggle == true ? (
                          <div class="list-group">
                            <div>
                              {replies.map((item) => {
                                return (
                                  <div key={item.id}>
                                    {replies.length === 0 ? (
                                      <h2>No replies</h2>
                                    ) : (
                                      <>
                                        <h6>{item.user?.username}</h6>
                                        <h5>{item.text}</h5>
                                      </>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                            <div
                              class="list-group-item"
                              style={{ padding: "00" }}
                            >
                              <div class="form-group">
                                <textarea
                                  required="required"
                                  placeholder="Your Reply"
                                  class="form-control"
                                  onChange={handleChange}
                                ></textarea>
                              </div>
                              <button
                                class="btn btn-info"
                                onClick={() => {
                                  addReply(items._id);
                                }}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comments;
