/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import Sidetable from "../ManageProjects/ProjectList/ProjectList";
import CustomizedDialogs from "../ManageProjects/ProjectList/index";

export default function projects({ changeValue }) {
  let [data, getdata] = useState();
  const Setdata = (e) => {
    getdata(e);
  };
  return (
    <>
      <Sidetable
        changeValue={data}
        Button={<CustomizedDialogs getData={Setdata} />}
      />
    </>
  );
}
