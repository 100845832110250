/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./reports.css";
import axios from "axios";
import moment from "moment";
import { withStyles } from "@material-ui/core";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from "@mui/x-data-grid";
import Url from "../../URL/url";
import { LinearProgress, MenuItem, TextField } from "@mui/material";
import { Box } from "@mui/system";

function Report(props) {
  const [allProjects, setAllProjects] = useState([]);
  const [allEmp, setAllEmp] = useState([]);

  const [currency, setCurrency] = useState({
    projname: "",
    employename: "",
    category: "",
    startdate: null,
    dueDate: null,
  });
  const { projname, employename, category, startdate, dueDate } = currency;

  let token = localStorage.getItem("TOKEN");
  const headers = { Authorization: `Bearer ${token}` };
  const getuser = JSON.parse(localStorage.getItem("USER"));

  let [userdata, setuserdata] = useState([]);

  useEffect(() => {
    const startD = new Date(startdate);
    const dueD = new Date(dueDate);

    axios
      .get(`https://newpms.iknowsolution.com/api/v1/tasks?user=${getuser.id}`, {
        headers,
      })
      .then((e) => setuserdata(e?.data.data));

    let url = "https://newpms.iknowsolution.com/api/v1/tasks";
    if (
      projname === "" &&
      employename === "" &&
      startdate == null &&
      dueDate == null
    ) {
      axios.get(url, { headers }).then((e) => {
        setgetdata(e.data.data);
      });
    } else if (
      projname !== "" &&
      employename === "" &&
      startdate == null &&
      dueDate == null
    ) {
      axios.get(`${url}?project=${projname}`, { headers }).then((e) => {
        setgetdata(e.data.data);
      });
    } else if (
      projname === "" &&
      employename !== "" &&
      startdate == null &&
      dueDate == null
    ) {
      axios.get(`${url}?user=${employename}`, { headers }).then((e) => {
        setgetdata(e.data.data);
      });
    } else if (
      projname === "" &&
      employename === "" &&
      startdate == null &&
      dueDate == null
    ) {
      axios.get(`${url}?category=${category}`, { headers }).then((e) => {
        setgetdata(e.data.data);
      });
    } else if (
      projname === "" &&
      employename === "" &&
      startdate !== null &&
      dueDate == null
    ) {
      axios
        .get(`${url}?startDate=${startD.toISOString()}`, { headers })
        .then((e) => {
          setgetdata(e.data.data);
        });
    } else if (
      projname === "" &&
      employename === "" &&
      startdate == null &&
      dueDate !== null
    ) {
      axios
        .get(`${url}?dueDate=${dueD.toISOString()}`, { headers })
        .then((e) => {
          setgetdata(e.data.data);
        });
    } else {
      axios
        .get(`${url}?project=${projname}&user=${employename}`, { headers })
        .then((e) => {
          setgetdata(e.data.data);
        });
    }
  }, [currency]);

  useEffect(() => {
    axios
      .get("https://newpms.iknowsolution.com/api/v1/users", { headers })
      .then((e) => {
        setAllEmp(e.data.data);
      });
    axios
      .get("https://newpms.iknowsolution.com/api/v1/projects", { headers })
      .then((e) => {
        setAllProjects(e.data.data.projects);
      });
    return () => {
      setAllProjects([]);
    };
  }, []);

  const handleChange = (e) => {
    setCurrency({ ...currency, [e.target.name]: e.target.value });
  };

  let [getdata, setgetdata] = useState(null);

  useEffect(() => {
    axios
      .get(`${Url()}/api/v1/tasks?user=${getuser.id}`, { headers })
      .then((e) => setuserdata(e?.data.data));

    axios.get(`${Url()}/api/v1/tasks`, { headers }).then((e) => {
      setgetdata(e.data.data);
    });
    return () => {
      setgetdata(null);
    };
  }, []);

  const rows =
    getuser.role === "PM" || getuser.role === "Admin" ? getdata : userdata;

  const columns = [
    {
      field: "project",
      headerName: "Employe Name",
      width: 150,
      valueGetter: (params) => {
        let a = params.row.user ? params.row.user.username : "empty";

        return a;
      },
    },
    {
      field: "user",
      headerName: "Project Name",
      width: 150,
      valueGetter: (params) => {
        let a = params.row.project ? params.row.project.name : "No Project";
        return a;
      },
    },
    {
      field: "name",
      headerName: "Task Name",
      width: 150,
      valueGetter: (params) => {
        let a = params.row?.name ? params.row?.name : "empty";
        return a;
      },
    },
    {
      field: "taskDetails",
      headerName: "Task Details",
      width: 267,

      valueGetter: (params) => {
        let a = params.row?.taskDetails ? params.row?.taskDetails : "empty";
        return a;
      },
    },
    {
      field: "category",
      headerName: "Category",
      width: 170,
      valueGetter: (params) => {
        let a = params.row.project?.category?.name
          ? params.row.project?.category?.name
          : "N/A";
        return a;
      },
    },
    {
      field: "startDate;",
      headerName: "Start Date",
      width: 130,
      valueGetter: (params) => {
        let a = moment(params.row.startDate).format("DD-MM-YYYY");
        return a;
      },
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      width: 130,
      valueGetter: (params) => {
        let a = moment(params.row.dueDate).format("DD-MM-YYYY");
        return a.toString();
      },
    },
    {
      field: "estimatedHours",
      headerName: "Estimated Hours",
      width: 150,
      valueGetter: (params) => {
        let a = params.row ? params.row.estimatedHours : "empty";

        return a;
      },
    },
    {
      field: "consumedHours",
      headerName: "Consume Hours",
      width: 150,
      valueGetter: (params) => {
        let a = params.row ? params.row.consumedHours : "empty";

        return a;
      },
    },
    { field: "currentStatus", headerName: "Status", width: 130 },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport style={{ color: "green" }} />
      </GridToolbarContainer>
    );
  }
  const [pageSize, setPageSize] = React.useState(10);
  return (
    <>
      <div className="dashboard_table">
        <div className="table_heading" style={{ color: "black" }}>
          Reports
        </div>
        <div className="table_box">
          {getuser.role === "PM" || getuser.role === "Admin" ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  padding: "10px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div>
                    <TextField
                      style={{ width: "250px" }}
                      id="outlined-select-currency"
                      select
                      size="small"
                      label="Project Name"
                      name="projname"
                      value={currency.projname}
                      onChange={handleChange}
                    >
                      {allProjects.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div style={{ marginLeft: "40px" }}>
                    <TextField
                      style={{ width: "250px" }}
                      id="outlined-select-currency"
                      select
                      size="small"
                      label="EmployeName"
                      value={currency.employename}
                      name="employename"
                      onChange={handleChange}
                    >
                      {allEmp.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.username}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                <div>
                  <TextField
                    style={{ width: "250px", paddingRight: "1rem" }}
                    id="outlined-select-currency"
                    select
                    size="small"
                    label="Weekly"
                    value={currency.employename}
                    name="employename"
                    onChange={handleChange}
                  >
                    {allEmp.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.username}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
              <div className="datefiel" style={{ padding: "10px" }}>
                <TextField
                  id="date"
                  label="Start Date"
                  type="date"
                  name="startdate"
                  onChange={handleChange}
                  size="small"
                  sx={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  id="date"
                  label="Due Date"
                  type="date"
                  name="dueDate"
                  onChange={handleChange}
                  size="small"
                  sx={{ width: 220, marginLeft: 2 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </>
          ) : null}

          <div style={{ height: 500, width: "100%" }}>
            {getdata ? (
              <DataGrid
                pageSize={pageSize}
                components={{
                  Toolbar: CustomToolbar,
                }}
                onPageSizeChange={(newPage) => setPageSize(newPage)}
                rows={rows}
                rowHeight={70}
                columns={columns}
              />
            ) : (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Report;
