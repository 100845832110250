import React, { useState } from "react";
import Dashboard from "../../Pages/dashboard/dashboard";
import Mytask from "../ManageTask/TaskList/mytask";
import Categories from "../ManageCategories/CategoriesList/categories";
import Allusers from "../ManageUsers/UsersList/allusers";
import Report from "../../Pages/reports/reports";
import Projects from "../projects/projects";
import { Route, Routes } from "react-router";
import Profile from "../../Pages/profile/profile";
import Changepassword from "../changePassword/changepassword";
import Editbutton from "../editbutton/editbutton";
import Dummy from "../../Pages/dummy";
import Loginpage from "../../Pages/loginpage/loginpage";

export default function Routing() {
  const [addCategory, setaddCategory] = useState(false);
  let [ref, getreferse] = useState(false);
  let ImageUrl = (e) => {
    getreferse(!ref);
  };
  return (
    <>
      <Routes>
        <Route path="/Loginpage" element={<Loginpage />} />
        <Route path="/" element={<Dashboard />} />
        <Route
          path="/Projects"
          element={<Projects changeValue={addCategory} />}
        />
        <Route
          path="/My-Task"
          element={<Mytask resultFunction={addCategory} />}
        />
        <Route
          path="/Categories"
          element={<Categories resultFunction={addCategory} />}
        />
        <Route
          path="/All-Users"
          element={<Allusers resultFunction={addCategory} />}
        />
        <Route path="/Report" element={<Report />} />
        <Route path="/Profile" element={<Profile changeIMage={ImageUrl} />} />
        <Route path="/Editbutton" element={<Editbutton />} />
        <Route path="/dummy" element={<Dummy />} />
        <Route path="/changepassword" element={<Changepassword />} />
        
        {/* <Route path="/resetpassword"element={<Resetpassword/>} /> */}
        
        {/* <Route path ="/taskdetails" element={<TaskDetail/>} /> */}
        
      </Routes>
    </>
  );
}
